const env = (typeof window === 'undefined' ? process.env : window.env) || {};

exports.env = env;

/**
 * Throws an error or logs a warning message if list of environment
 * variables are not available
 * @param  {Array} envVarsToValidate - Array of env variables
 * @param  {Boolean} shouldThrow - Boolean to control if it should throw or log
 */
exports.validateEnvVars = (envVarsToValidate = [], shouldThrow = false) => {
  const missingEnvVariables = envVarsToValidate.reduce((acc, curr) => {
    if (env[curr]) {
      return acc;
    }

    return [
      ...acc,
      curr,
    ];
  }, []);

  const hasMissingEnvVariables = missingEnvVariables.length > 0;
  const message = `Missing required env variables: '${missingEnvVariables.join(', ')}'. Make sure you have a '.env' file or that you are running a command with inline environment variables.`;

  if (hasMissingEnvVariables && shouldThrow) {
    throw new Error(message);
  }

  if (hasMissingEnvVariables && !shouldThrow) {
    console.warn(message);
  }
};


exports.getEnv = (key) => env[`${key}${__CLIENT__ ? '_CLIENT' : ''}`] || null;
