import { isLocationBasedPageSelector } from '@wxu/contexts/src/page/selectors';
import {
  createDeepEqualSelector,
  dalSelector,
  createDalKey,
} from '@wxu/contexts/src/redux-dal/selectors';
import { countryCodeSelector } from '@wxu/contexts/src/i18n/selectors';
import { locationApiCallsSelector } from './fromPageParam';

/**
 * Gets the raw location v3 response
 */
export const pageLocationResponseSelector = createDeepEqualSelector(
  dalSelector,
  locationApiCallsSelector,
  (dal, apiCalls) => {
    const callKey = 'getSunV3LocationPointUrlConfig';
    const rootObj = dal[callKey] || {};
    const params = apiCalls[callKey] || {};
    const paramsKey = createDalKey(params);

    return rootObj[paramsKey];
  },
);

/**
 * Gets pageLocation data if successfully loaded
 */
export const pageLocationDataSelector = createDeepEqualSelector(
  pageLocationResponseSelector,
  /**
   * @param  {Object<string,*>} response
   * @return {Object<string,*>}
   */
  (response) => {
    const loaded = response?.loaded ?? null;

    if (!loaded) {
      return null;
    }

    const data = response?.data ?? null;

    if (!data || data.errors) {
      return null;
    }

    return response.data.location;
  },
);

export const pageLocationIsInvalidIdSelector = createDeepEqualSelector(
  isLocationBasedPageSelector,
  pageLocationResponseSelector,
  (isLocationBasedPage, response) => {
    if (!isLocationBasedPage) return false;
    if (!response) return true;

    const errors = response?.data?.errors ?? [];

    if (Array.isArray(errors) && errors.length) {
      const errorCode = errors[0]?.error?.code ?? '';
      // Match 400 and above error codes
      const notFoundCodePattern = /(4\d{2}|5\d{2})$/;

      return notFoundCodePattern.test(errorCode);
    }

    return false;
  },
);

/**
 * Gets geocode
 */
export const pageLocationGeocodeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  (locData) => {
    if (!locData) {
      return '';
    }
    return `${locData.latitude},${locData.longitude}`;
  },
);

/**
 * Gets latitude
 */
export const pageLocationLatitudeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {number}
   */
  locData => locData?.latitude ?? undefined,
);

/**
 * Gets longitude
 */
export const pageLocationLongitudeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {number}
   */
  locData => locData?.longitude ?? undefined,
);

/**
 * Gets adminDistrict; e.g. "Georgia"
 */
export const pageLocationAdminDistrictSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.adminDistrict ?? '',
);

/**
 * Gets adminDistrictCode; e.g. "GA"
 */
export const pageLocationAdminDistrictCodeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.adminDistrictCode ?? '',
);

/**
 * Gets postalCode; e.g. "30337"
 */
export const pageLocationPostalCodeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.postalCode ?? '',
);

/**
 * Gets the neighborhood this location's geocode
 * is within, if any; e.g. "Eagan Park"
 */
export const pageLocationNeighborhoodSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.neighborhood ?? '',
);

/**
 * Gets country name; e.g. "United States"
 */
export const pageLocationCountrySelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.country ?? '',
);

/**
 * Gets countryCode; e.g. "US"
 */
export const pageLocationCountryCodeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.countryCode ?? '',
);

/**
 * Gets ianaTimeZone; e.g. "America/New_York"
 */
export const pageLocationIanaTimeZoneSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  (locData) => {
    let ianaTimeZone = locData?.ianaTimeZone ?? '';

    // Provide dateTime compatible timezone fallback if ianaTimeZone is null or a number
    if (!ianaTimeZone || !ianaTimeZone.includes('/')) {
      ianaTimeZone = 'Etc/UTC';
    }

    /**
     * @return {string}
     */
    return ianaTimeZone;
  }
);

/**
 * Gets displayName; e.g. "Atlanta"
 */
export const pageLocationDisplayNameSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.displayName ?? '',
);

/**
 * Gets dmaCd; e.g. "524"
 */
export const pageLocationDmaCdSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.dmaCd ?? '',
);

/**
 * Gets placeId; e.g. "3474be034807c5d9ff1e0183e965448c7abbb337e21116050616f1fdf84d721b"
 */
export const pageLocationPlaceIdSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.placeId ?? '',
);

/**
 * Gets airportName if this location contains an
 * airport; e.g. "Hartsfield-Jackson Intl"
 */
export const pageLocationAirportNameSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.airportName ?? '',
);

/**
 * Gets the location type; e.g. "neighborhood", "city",
 * "address", "poi", "ski", "locid", "pws"
 */
export const pageLocationTypeSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.type ?? '',
);

/**
 * Gets the city; e.g. "Atlanta"
 */
export const pageLocationCitySelector = createDeepEqualSelector(
  pageLocationDataSelector,
  /**
   * @return {string}
   */
  locData => locData?.city ?? '',
);

/**
 * Gets the canonicalCityId; e.g. "36fc5df08dce7eb0bba1d99cfe8973ec407f8253eb601fe8be4358220a63866a"
 */
export const pageLocationCanonicalCityIdSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  locData => locData?.canonicalCityId ?? '',
);

/**
 * Gets the disputedArea; e.g. true
 */
export const pageLocationDisputedAreaSelector = createDeepEqualSelector(
  pageLocationDataSelector,
  locData => locData?.disputedArea ?? false,
);

/**
 * Creates from location data a formatted presentation name.
 * For formatting rules, see:
 * https://github.com/TheWeatherCompany/wxu-docs/blob/master/architecture/weather-com/nerf/location-presentation-names.md
 *
 * @param  {Object}   args
 * @param  {string}   args.displayName
 * @param  {string}   args.adminDistrict
 * @param  {string}   args.adminDistrictCode
 * @param  {string}   args.countryCode
 * @param  {string}   args.country
 * @param  {Boolean}  args.disputedArea
 * @param  {string}   args.localeCountryCode
 * @param  {string}   args.separator          String used to join items, e.g. ', ', or '+'
 * @return {string}
 */
export function formatPresentationName({
  displayName,
  adminDistrict,
  adminDistrictCode,
  countryCode,
  country,
  disputedArea,
  localeCountryCode,
  separator = ', ',
}) {
  const arr = [];

  if (displayName) arr.push(displayName);

  // stupid hard-coding logic for Israel
  // see https://jira.weather.com:8443/browse/WEB-10802
  if (localeCountryCode !== 'IL') {
    if (adminDistrictCode) {
      arr.push(adminDistrictCode);
    } else if (adminDistrict) {
      arr.push(adminDistrict);
    }
  }

  // Stricly check for a false `disputedArea`
  // If it's not false (including falsy values), treat it as disputed
  // Better to be cautious here
  if (countryCode !== localeCountryCode && disputedArea === false && country) {
    arr.push(country);
  }

  return arr.join(separator);
}

/**
 * Gets the presentation name for the current location
 */
export const pageLocationPresentationNameSelector = createDeepEqualSelector(
  pageLocationDisplayNameSelector,
  pageLocationAdminDistrictSelector,
  pageLocationAdminDistrictCodeSelector,
  pageLocationCountryCodeSelector,
  pageLocationCountrySelector,
  pageLocationDisputedAreaSelector,
  countryCodeSelector,
  (
    displayName,
    adminDistrict,
    adminDistrictCode,
    countryCode,
    country,
    disputedArea,
    localeCountryCode,
  ) => {
    const presentationName = formatPresentationName({
      displayName,
      adminDistrict,
      adminDistrictCode,
      countryCode,
      country,
      disputedArea,
      localeCountryCode,
    });

    return presentationName;
  }
);
