import { createAction } from 'redux-actions';
import {
  CHANGE_EMAIL_RESPONSE_ACTION,
  CHANGE_PASSWORD_RESPONSE_ACTION,
  CHANGE_PASSWORD_LOGIN_RESPONSE_ACTION,
  CHANGE_EMAIL_LOGIN_RESPONSE_ACTION,
  CURRENT_EMAIL_ACTION,
  IS_LOADING_ACTION,
  MEMBER_CHANGED_PASSWORD_ACTION,
  SET_USER_ACCOUNT_ACTION,
  SET_UPS_USER_INFO_ACTION,
  CLOSE_CHANGE_PASSWORD_MODAL,
  SET_USER_STATE,
  SET_USER_HAS_LOGIN,
} from '../constants';

export const CREATE_USER_ACCOUNT_RESPONSE = 'WXU/USER/CREATE_ACCOUNT/RESPONSE';

export const userChangeEmailAction = createAction('userChangeEmailAction');

export const changeEmailResponseAction = createAction(CHANGE_EMAIL_RESPONSE_ACTION);
export const changePasswordResponseAction = createAction(CHANGE_PASSWORD_RESPONSE_ACTION);
export const changePasswordLoginResponseAction = createAction(
  CHANGE_PASSWORD_LOGIN_RESPONSE_ACTION
);
export const changeEmailLoginResponseAction = createAction(
  CHANGE_EMAIL_LOGIN_RESPONSE_ACTION
);
export const currentEmailAction = createAction(CURRENT_EMAIL_ACTION);
export const isLoadingAction = createAction(IS_LOADING_ACTION);
export const setUserAccountAction = createAction(SET_USER_ACCOUNT_ACTION);
export const setUpsUserInfoAction = createAction(SET_UPS_USER_INFO_ACTION);
export const ups2ChangedPasswordAction = createAction(MEMBER_CHANGED_PASSWORD_ACTION);
export const closeChangePasswordModalAction = createAction(CLOSE_CHANGE_PASSWORD_MODAL);
export const setUserStateAction = createAction(SET_USER_STATE);
export const setUserHasLogin = createAction(SET_USER_HAS_LOGIN);
/**
 * This action returns the responses after creating a new user
 */
export const createUserAccountResponse = createAction(CREATE_USER_ACCOUNT_RESPONSE);
