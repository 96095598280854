import {
  createRehydrateAction,
  persistPending,
  persistComplete,
} from './actions';
import { PERSIST_STATUS } from './constants';

/**
 * Creates a reducer map to be consumed by other reducers, for rehydrating their state.
 * @param  {string} stateKey
 * @return {Object<*, Function>} reducerMap
 */
export function getRehydrationReducerMap(stateKey) {
  const rehydrateAction = createRehydrateAction(stateKey);

  return {
    [rehydrateAction]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [persistPending]: state => ({
      ...state,
      [PERSIST_STATUS]: 'pending',
    }),
    [persistComplete]: state => ({
      ...state,
      [PERSIST_STATUS]: 'complete',
    }),
  };
}
