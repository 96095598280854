import get from 'lodash/get';
import { createSelector } from 'reselect';
import { serverPageStateQuerySelector } from '@wxu/contexts/src/page/selectors';
import { dalSelector, createDalKey, createDeepEqualSelector } from '@wxu/contexts/src/redux-dal/selectors';

export const moduleInstanceIdSelector = createSelector(
  serverPageStateQuerySelector,
  pageQueryParams => pageQueryParams?.moduleInstanceId
);

export const moduleInstanceConfigSelector = createDeepEqualSelector(
  moduleInstanceIdSelector,
  dalSelector,
  (moduleInstanceId, dalState) => {
    const dalKey = createDalKey({
      entityType: 'module_instance',
      entityId: moduleInstanceId,
    });

    const moduleInstanceConfig = get(dalState, [
      'getMewEntityUrlConfig',
      dalKey,
      'data',
      'data',
      'attributes',
      'configuration',
    ]);

    return moduleInstanceConfig || {};
  },
);
