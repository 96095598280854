export const ESSENTIAL_TECHNOLOGY = 'essential-technology';
export const INTERNATIONAL_TRANSFER = 'international-transfer';
export const FUNCTIONAL_TECHNOLOGY = 'functional-technology';
export const GEOGRAPHICALLY_RELEVANT_ADVERTISING = 'geographically-relevant-advertising';
export const SALE_OF_DATA = 'sale-of-data';
export const SENSITIVE_DATA = 'sensitive-data';

export type Purpose =
  // Restrictive Regimes
  typeof ESSENTIAL_TECHNOLOGY |
  typeof FUNCTIONAL_TECHNOLOGY |
  typeof GEOGRAPHICALLY_RELEVANT_ADVERTISING |
  // PIPL
  typeof INTERNATIONAL_TRANSFER |
  // CCPA
  typeof SALE_OF_DATA |
  typeof SENSITIVE_DATA;

export type UserConsents = {
  [key in Purpose]?: boolean
};

// SourcePoint TCF v2.2 IAB consents
export const SELECT_PERSONALISED_CONTENT = 'Select personalised content';
export const SELECT_PERSONALISED_ADS = 'Select personalised ads';
export const SELECT_BASIC_ADS = 'Select basic ads';
export const CREATE_A_PERSONALISED_ADS_PROFILE = 'Create a personalised ads profile';
export const CREATE_A_PERSONALISED_CONTENT_PROFILE = 'Create a personalised content profile';
export const MEASURE_AD_PERFORMANCE = 'Measure ad performance';
export const APPLY_MARKET_RESEARCH_TO_GENERATE_AUDIENCE_INSIGHTS = 'Apply market research to generate audience insights';
export const DEVELOP_AND_IMPROVE_PRODUCTS = 'Develop and improve products';
export const STORE_AND_OR_ACCESS_INFORMATION_ON_A_DEVICE = 'Store and/or access information on a device';
export const MEASURE_CONTENT_PERFORMANCE = 'Measure content performance';

// SourcePoint TCF v2.2 Custom consents
export const TCF_CUSTOM_PURPOSES = [
  FUNCTIONAL_TECHNOLOGY,
  GEOGRAPHICALLY_RELEVANT_ADVERTISING,
];

export const PURPOSES_USER_HAS_CHOICE = [
  ...TCF_CUSTOM_PURPOSES,
  SALE_OF_DATA,
  SENSITIVE_DATA,
];

