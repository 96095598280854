import isEqual from 'lodash/isEqual';
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import { REDUCER_KEY } from './constants';

export const createDalKey = (params = {}) => Object.keys(params)
  .sort()
  .map((paramKey) => {
    const param = params[paramKey];
    const isObject = !!param && typeof param === 'object';

    if (isObject) {
      return `${paramKey}:{${createDalKey(param)}}`;
    }

    return `${paramKey}:${param}`;
  })
  .join(';');

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const dalSelector = state => state?.[REDUCER_KEY] ?? {};

/**
 * Selector which returns a function to get DAL data from a supplied URL config.
 * ## Example:
 * ```js
 * const someDataSelector = createSelector(
 *   someUrlConfigSelector,
 *   getDalDataSelector,
 *   (urlConfig, getDalData) => getDalData(urlConfig)
 * );
 * ```
 */
export const getDalDataSelector = createSelector(
  dalSelector,
  dal => (config, getFullResponse) => {
    const {
      params,
      name,
    } = config;
    const dalKey = createDalKey(params);

    return getFullResponse ? dal?.[name]?.[dalKey] : dal?.[name]?.[dalKey]?.data;
  }
);
