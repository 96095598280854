import { call, put, select } from 'redux-saga/effects';
import { createLogger } from '@wxu/logger';
import { createRehydrateAction } from '../actions';
import { getStateFromKeySelector } from '../selectors';
import { getFromStorage } from '../storage';
import { getAllowedState } from './getAllowedState';

const logger = createLogger('web-storage:rehydrate');

/**
 * Rehydrates from given key in storage to given key in redux state.
 * @param {string}    reducerKey            Key in state
 * @param {Object}    [options]
 * @param {string}    [options.key]         Key in storage (defaults to reducerKey)
 * @param {string[]}  [options.allowlist]   If defined, persisted keys of sub-state to rehydrate
 */
export function* rehydrateSaga(reducerKey, {
  key = reducerKey,
  allowlist = [],
} = {}) {
  const rehydrateAction = yield call(createRehydrateAction, reducerKey);

  yield call(
    rehydrateFromStorage,
    reducerKey,
    key,
    rehydrateAction,
    allowlist,
  );
}

/**
 * Gets state from storage, and merges with redux state.
 * State in storage takes precedence over existing state (if any) in redux.
 * @param {string}   reducerKey
 * @param {string}   key
 * @param {Function} rehydrateAction
 * @param {string[]} allowlist
 * @param {Function} stateFromKeySelector
 */
export function* rehydrateFromStorage(
  reducerKey,
  key,
  rehydrateAction,
  allowlist,
  stateFromKeySelector = getStateFromKeySelector(reducerKey),
) {
  // In this case, all data stored in storage should be JSON-parseable
  let persistedState = {};

  try {
    const storedState = yield call(getFromStorage, key);

    persistedState = yield call(getAllowedState, storedState, allowlist);
  } catch (err) {
    logger.debug(`Data at ${key} unavailable`);
  }
  const reduxState = yield select(stateFromKeySelector);
  // Merge persistedState with reduxState, with storeState taking priority in conflicting cases
  const state = {
    ...reduxState,
    ...persistedState,
  };

  yield put(rehydrateAction(state));
}
