import get from 'lodash/get';
import { createSelector } from 'reselect';
import { env } from '@wxu/env';
import { getDalDataSelector } from '@wxu/contexts/src/redux-dal/selectors';
import { localeSelector } from '@wxu/contexts/src/i18n/selectors';
import {
  pageKeySelector,
  siteModeOverrideSelector,
} from '@wxu/contexts/src/page/selectors';
import {
  linkListId,
  language,
  SITE_MODE_NORMAL,
  SITE_MODE_HYBRID,
  SITE_MODE_SEVERE,
} from '../constants';

/**
 * Get siteModeLinkLists from state.
 * Helper selector, not intended for external use.
 *
 * @returns {object}
 */
const siteModeLinkListSelector = createSelector(
  getDalDataSelector,
  (getDalData) => {
    const data = getDalData({
      name: 'getCMSLinkListUrlConfig',
      params: {
        language,
        linkListId,
      },
    });

    return data?.links ?? [];
  }
);

/**
 * Massage siteModeLinkLists to a more consumable structure.
 * Helper selector, not intended for external use.
 *
 * @returns {object}
 */
const severeModeLinksSelector = createSelector(
  siteModeLinkListSelector,
  (siteModeLinkList) => {
    if (siteModeLinkList) {
      return siteModeLinkList.map(
        link => ({
          mode: get(link, ['attributes', 'mode'], 'normal'),
          locale: get(link, ['attributes', 'locale'], 'en-US'),
        }),
      );
    }

    return [];
  }
);

function getNormalizedSiteMode({ siteMode, pageKey, bypassSiteModeAllowList }) {
  // AllowList of pages that should honor siteMode. This allows us to avoid creating redundant page configs or other
  // duplicate logic checks for pages that don't care about site mode. Adding a page key here means that severe mode
  // styles like gradients will be used on the page. Some special components disobey this list (through selector param)
  // to perform custom logic
  const siteModePageKeyAllowList = [
    'home',
  ];

  if (bypassSiteModeAllowList || siteModePageKeyAllowList.includes(pageKey)) {
    if (siteMode === 'hybrid' || siteMode === 'severe1') {
      return SITE_MODE_HYBRID;
    }
    if (siteMode === 'severe' || siteMode === 'severe2') {
      return SITE_MODE_SEVERE;
    }
  }
  if (siteMode === 'test') return 'test';
  return SITE_MODE_NORMAL;
}

/**
 * Get site mode from a cms call, with possible header/query over-ride.
 * Use '?twc-headers=twc-site-mode:${mode}' to over-ride.
 * Defaults to 'normal', but can be 'test, 'hybrid', 'severe', 'severe1', or 'severe2'.
 */
export const getSiteModeSelector = ({ bypassSiteModeAllowList } = { bypassSiteModeAllowList: false }) => createSelector(
  localeSelector,
  severeModeLinksSelector,
  siteModeOverrideSelector,
  pageKeySelector,
  (
    locale,
    severeModeLinks,
    siteModeOverride,
    pageKey,
  ) => {
    // Check if header or query param has been set to force a particular site mode and use that for default
    if (env.WEBCAKES_ENV !== 'PROD' && siteModeOverride) return getNormalizedSiteMode({ siteMode: siteModeOverride, pageKey, bypassSiteModeAllowList });
    let siteMode = SITE_MODE_NORMAL;

    severeModeLinks.forEach((link) => {
      const { locale: linkLocale, mode: linkMode } = link;

      // check if current locale matches locale in one of the links in linklist
      if (locale === linkLocale) {
        siteMode = getNormalizedSiteMode({ siteMode: linkMode, pageKey, bypassSiteModeAllowList });
      }
    });

    return siteMode;
  },
);
