import { createLogger } from '@wxu/logger';

const logger = createLogger('web-storage');

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
export function detectLocalStorageAvailable() {
  try {
    localStorage.setItem('test', {});
    localStorage.removeItem('test', {});

    return true;
  } catch (e) {
    const quotaExceeded = e instanceof DOMException && (
      // everything except Firefox
      e.code === 22
      // Firefox
      || e.code === 1014
      // test name field too, because code might not be present
      // everything except Firefox
      || e.name === 'QuotaExceededError'
      // Firefox
      || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      && (localStorage && localStorage.length !== 0);

    if (quotaExceeded) {
      return true;
    }

    logger.error(e);
    return false;
  }
}


export function detectSessionStorageAvailable() {
  try {
    sessionStorage.setItem('test', {});
    sessionStorage.removeItem('test', {});

    return true;
  } catch (e) {
    logger.error(e);
    return false;
  }
}
