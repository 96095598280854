const TRACE = 'trace';
const DEBUG = 'debug';
const INFO = 'info';
const WARN = 'warn';
const ERROR = 'error';

exports.TRACE = TRACE;
exports.DEBUG = DEBUG;
exports.INFO = INFO;
exports.WARN = WARN;
exports.ERROR = ERROR;
exports.DEFAULT_LOG_LEVEL = ERROR;
exports.LOG_LEVELS = [
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
];
