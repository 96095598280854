export const WEATHER_MODE_NORMAL = 'normal';
export const WEATHER_MODE_ACTIVE = 'active';
export const WEATHER_MODE_SEVERE = 'severe';

export const WEATHER_MODES = [
  WEATHER_MODE_NORMAL, // bright, sunshine-y day
  WEATHER_MODE_ACTIVE, // gettin' a little rain
  WEATHER_MODE_SEVERE, // batten down ye' hatches
];

export const ALERTS_URL_CONFIG_NAME = 'getSunWeatherAlertHeadlinesUrlConfig';
export const CURRENT_OBS_URL_CONFIG_NAME = 'getSunV3CurrentObservationsUrlConfig';
export const DAILY_FORECAST_URL_CONFIG_NAME = 'getSunV3DailyForecastWithHeadersUrlConfig';
export const POLLEN_FORECAST_URL_CONFIG_NAME = 'getSunIndexPollenDaypartUrlConfig';
