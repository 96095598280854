import { createLogger } from '@wxu/logger';
import {
  Purpose,
  PURPOSES_USER_HAS_CHOICE,
  UserConsents,
} from './configs/purposesConfig';

export const logger = createLogger('DprSdk');

export const isObject = (variable) => (
  typeof variable === 'object' && !Array.isArray(variable) && variable !== null
);

export const validateUserConsent = (purpose, value) => {
  const validPurpose = PURPOSES_USER_HAS_CHOICE.includes(purpose);
  const validConsent = typeof value === 'boolean';

  return validPurpose && validConsent;
};

export const validateAndCleanUpUserConsents = (userConsents: UserConsents) => {
  const validatedUserConsents = isObject(userConsents) ? (Object.keys(userConsents) as Purpose[]).reduce(
    (accum: UserConsents, currPurpose) => {
      if (validateUserConsent(currPurpose, userConsents[currPurpose])) {
        accum[currPurpose] = userConsents[currPurpose];
      }

      return accum;
    }, {}
  ) : undefined;

  return validatedUserConsents;
};

export const getUserConsentsChanged = (newUserConsents: UserConsents, userConsents: UserConsents) => {
  const newUserConsentsPurposes = Object.keys(newUserConsents) as Purpose[];

  return newUserConsentsPurposes.reduce((accum: Purpose[], purpose) => {
    if (newUserConsents[purpose] !== userConsents[purpose]) {
      accum.push(purpose);
    }

    return accum;
  }, []);
};

export function isWuHost() {
  return window?.top?.location?.host?.includes?.('wunderground');
}

export async function racePromiseAgainstTimeout({
  promise,
  maxTimeMs,
}: {
  promise: Promise<any>;
  maxTimeMs: number;
}) {
  return await Promise.race([
    promise,
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('timeout won');
      }, maxTimeMs);
    }),
  ]);
}
