import { createSelector } from 'reselect';
import {
  createDeepEqualSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import { localeSelector } from '@wxu/contexts/src/i18n/selectors';
import {
  pageParamsLocIdSelector,
  queryParamLocIdSelector,
  queryParamPlaceIdSelector,
  queryParamCanonicalCityIdSelector,
} from '@wxu/contexts/src/page/selectors';

/**
 * @param {string} encodedURIComponent
 * @returns {string}
 */
function decodeURIComponentSafely(encodedURIComponent) {
  try {
    return decodeURIComponent(encodedURIComponent);
  } catch (err) {
    return '';
  }
}

/**
 * Get the type and cleaned-up value of the "dirty" pageParamLocId.
 * Returns an object containing both the loc's type and its value,
 * since they're determined at the same time by the same logic.
 * The type options are:
 * - "geocode"
 * - "locId" (for legacy locIds, any type other than 4 or 9)
 * - "postalKey" (converted from legacy locIds type 4)
 * - "iataCode" (converted from legacy locIds type 9)
 * - "placeId"
 * - "canonicalCityId"
 * - "none" (if request path contained no pageParamLocId)
 *
 * This selector provides the calculated, memoized values to the
 * individual selectors for type and values:
 * - pageParamLocTypeSelector
 * - pageParamLocValueSelector
 *
 * @returns {*} typeAndValue
 */
export const pageParamLocTypeAndValueSelector = createDeepEqualSelector(
  pageParamsLocIdSelector,
  queryParamLocIdSelector,
  queryParamPlaceIdSelector,
  queryParamCanonicalCityIdSelector,
  (pageParamLocId, queryParamLocId, queryParamPlaceId, queryParamCanonicalCityId) => {
    if (!pageParamLocId && !queryParamLocId && !queryParamPlaceId && !queryParamCanonicalCityId) {
      return null;
    }

    // Prioritized list of location identifiers
    const proposedLocations = [
      {
        type: 'canonicalCityId', // We know the type already
        locId: decodeURIComponentSafely(queryParamCanonicalCityId),
      },
      {
        type: 'placeId', // We know the type already
        locId: decodeURIComponentSafely(queryParamPlaceId),
      },
      {
        type: null,
        locId: decodeURIComponentSafely(queryParamLocId),
      },
      {
        type: null,
        locId: decodeURIComponentSafely(pageParamLocId),
      },
    ];
    // Removed proposed locations with falsy locIds
    const locationToUse = proposedLocations.find(proposedLocId => !!proposedLocId.locId);

    // Bail early if no truthy locations
    if (!locationToUse) return null;

    const seoPhraseLocIdRegex = /.*(\+|-|\s)+(\w*|(\w{4}\d{4})|(\d+))(:(1|4|5|9|11|13|16|17|19|21|25|27))?(:\w{2})?$/;
    const seoPhraseMinus = /-/;
    const seoPhraseSpace = /\s/;
    const { locId } = locationToUse;

    // check for SEO Phrases and return a match from first part
    if (seoPhraseLocIdRegex.test(locationToUse.locId)) {
      if (seoPhraseSpace.test(locId)) {
        locationToUse.locId = locId.split(' ').pop();
      } else if (seoPhraseMinus.test(locId)) {
        locationToUse.locId = locId.split('-').pop();
      } else {
        locationToUse.locId = locId.split('+').pop();
      }

      const seoRetObj = getTypeAndValueObject(locationToUse);

      if (seoRetObj) {
        return seoRetObj;
      }
    }

    // if not SEO Phrase, return a match from whole string
    return getTypeAndValueObject(locationToUse);
  },
);

/**
 * Get the name and params for all the calls required
 * in registerApis.js for the location call/calls.
 *
 * This can be used in registerApis.js, of course; but
 * also re-used with dalSelector to select data from
 * within the responses.
 */
export const locationApiCallsSelector = createDeepEqualSelector(
  localeSelector,
  pageParamLocTypeAndValueSelector,
  (language, locTypeAndValue) => {
    const { type, value } = locTypeAndValue || {};
    const params = {
      language,
      [type]: value,
    };

    if (type === 'locId') {
      params.locationType = 'locid'; // SUN casing not consistent
    }

    return {
      getSunV3LocationPointUrlConfig: params,
    };
  },
);

/**
 * Normalizes the given geocode.
 *
 * @param      {string}  geocode  The geocode
 * @return     {string}
 */
export function normalizeGeocode(geocode) {
  if (!geocode) return '';

  const [lat, long] = geocode.split(',');
  const fixedLat = normalizeLatOrLong(lat);
  const fixedLong = normalizeLatOrLong(long);

  return `${fixedLat},${fixedLong}`;
}

/**
 * Normalizes the given latitude or longitude.
 *
 * @param      {string}  latOrLong  The lat or long
 * @return     {string}
 */
export function normalizeLatOrLong(latOrLong) {
  if (!latOrLong) return '';

  const fixedLatOrLong = (+`${Math.round(`${latOrLong}e2`)}e-2`).toFixed(2);

  return fixedLatOrLong;
}

/**
 * HELPER: getTypeAndValueObject(locString)
 *
 * Tests the location with regexes to find the
 * type, then returns the typeAndValue object.
 *
 * @param   {Object}  location
 * @param   {string}  location.locId
 * @param   {string}  location.type
 * @return  {Object<string,string>}
 */
export function getTypeAndValueObject({ locId, type }) {
  // ordered search for the type of the locString
  const testOrder = [
    {
      // for geocodes
      regex: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
      typeAs: 'geocode',
      valueFn: val => normalizeGeocode(val),
    }, {
      // for fullLocIds
      regex: /^([^+|^:]*):(1|4|5|9|11|13|16|17|19|21|25|27):\w{2}$/,
      typeAs: 'locId',
      valueFn: val => val.toUpperCase(),
    }, {
      // for usZipCodes
      regex: /^\d{5}(:\d)?(\w{2})?$/,
      typeAs: 'locId',
      valueFn: val => `${val.split(':')[0]}:4:US`,
    }, {
      // for partialLocIdType1s
      regex: /^\w{4}\d{4}(:1)?$/,
      typeAs: 'locId',
      valueFn: (val) => {
        const countryCode = val.substring(0, 2).toUpperCase();
        const result = val.split(':')[0];

        return `${result}:1:${countryCode}`;
      },
    }, {
      // for partialLocIdType9s
      regex: /^([a-zA-Z]{3}):.*$/,
      typeAs: 'locId',
      valueFn: val => `${val.split(':')[0].toUpperCase()}:9:US`,
    }, {
      // for placeIds
      regex: /^([0-9a-f]){32,}$/,
      typeAs: 'placeId',
      valueFn: val => val,
    }, {
      // for canonicalCityId
      regex: /^([0-9a-f]){32,}$/,
      typeAs: 'canonicalCityId',
      valueFn: val => val,
    },
  ];

  // Type has been supplied, try to use test for given type
  if (type) {
    const locTest = testOrder.find(test => test.typeAs === type);

    if (locTest && locTest.regex.test(locId)) {
      return {
        type: locTest.typeAs,
        value: locTest.valueFn(locId),
      };
    }
  }

  for (let i = 0, len = testOrder.length; i < len; i++) {
    const locTest = testOrder[i];

    if (locTest.regex.test(locId)) {
      return {
        type: locTest.typeAs,
        value: locTest.valueFn(locId),
      };
    }
  }

  return null;
}

export const isInvalidLocParamSelector = createSelector(
  pageParamLocTypeAndValueSelector,
  (typeAndValue) => {
    if (!typeAndValue) return true;

    const { type, value } = typeAndValue;

    if (!value) return true;
    if (type === 'geocode' && value === '0.00,0.00') return true;

    return false;
  }
);
