import {
  CA,
  CA_QC,
  EXEMPT,
  GDPR,
  JP,
  KR,
  LATAM_CO,
  LATAM_DO,
  LATAM_PE,
  LGPD,
  PIPL,
  TR_KVKK,
  USA,
  USA_CO,
  USA_CT,
  USA_CCPA,
  USA_VA,
  Regime,
} from './regimesConfig';
import {
  ESSENTIAL_TECHNOLOGY,
  INTERNATIONAL_TRANSFER,
  FUNCTIONAL_TECHNOLOGY,
  GEOGRAPHICALLY_RELEVANT_ADVERTISING,
  SALE_OF_DATA,
  SENSITIVE_DATA,
  Purpose,
} from './purposesConfig';


interface RegimePurposesConfig {
  name: Regime,
  purposes: {
    name: Purpose,
    value: boolean,
  }[],
}

export type RegimeDefaultPurposes = {
  [key in Purpose]?: boolean
};

export const regimesPurposesConfigs: ReadonlyArray<RegimePurposesConfig> = [
  {
    name: CA,
    purposes: [{
      name: SALE_OF_DATA,
      value: true,
    }],
  },
  {
    name: CA_QC,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: EXEMPT,
    purposes: [],
  },
  {
    name: GDPR,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: JP,
    purposes: [],
  },
  {
    name: KR,
    purposes: [],
  },
  {
    name: LATAM_CO,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: LATAM_DO,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: LATAM_PE,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: LGPD,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: PIPL,
    purposes: [
      {
        name: INTERNATIONAL_TRANSFER,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
    ],
  },
  {
    name: TR_KVKK,
    purposes: [
      {
        name: ESSENTIAL_TECHNOLOGY,
        value: true,
      },
      {
        name: FUNCTIONAL_TECHNOLOGY,
        value: false,
      },
      {
        name: GEOGRAPHICALLY_RELEVANT_ADVERTISING,
        value: false,
      },
    ],
  },
  {
    name: USA,
    purposes: [
      {
        name: SALE_OF_DATA,
        value: true,
      },
    ],
  },
  {
    name: USA_CCPA,
    purposes: [
      {
        name: SALE_OF_DATA,
        value: true,
      },
      {
        name: SENSITIVE_DATA,
        value: true,
      },
    ],
  },
  {
    name: USA_CO,
    purposes: [{
      name: SALE_OF_DATA,
      value: true,
    }],
  },
  {
    name: USA_CT,
    purposes: [{
      name: SALE_OF_DATA,
      value: true,
    }],
  },
  {
    name: USA_VA,
    purposes: [
      {
        name: SALE_OF_DATA,
        value: true,
      },
    ],
  },
];

export const isPurposePartOfRegime = ({
  regime,
  purpose,
}) => regimesPurposesConfigs.find(config => {
  const {
    name: currRegime,
    purposes,
  } = config;

  if (currRegime === regime) {
    return purposes.find(({ name: currPurpose }) => {
      if (purpose === currPurpose) {
        return true;
      }

      return false;
    });
  }

  return false;
});
