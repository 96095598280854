import {
  TABOOLA_HACK_LOCALE_EXCLUSIONS,
  TABOOLA_HACK_PAGE_KEYS,
} from './constants';
import { getTaboolaProps } from './getTaboolaProps';
import { normalizeModuleProps } from './normalizeModuleProps';

/**
 * function doTaboolaHack(config, pageKey)
 *
 * Adds the appropriate Taboola generic module
 * to the page (intended only for International
 * pages)
 *
 * A TEMPORARY HACK
 * Remove this once MEW makes management of
 * Taboola modules through normal page configs
 * sufficiently feasible!
 *
 * @param {*} pageConfig
 * @param {*} pageKey
 * @param {*} locale
 */
export const doTaboolaHack = (pageConfig, pageKey, locale) => {
  // take no action if we're in an excluded locale
  if (TABOOLA_HACK_LOCALE_EXCLUSIONS.includes(locale)) return;

  // only hack the configs for certain pageKeys
  if (!TABOOLA_HACK_PAGE_KEYS.includes(pageKey)) return;

  const { regions: pageRegions } = pageConfig;
  const { main: mainRegion } = pageRegions;
  const regionNames = Object.keys(pageRegions);
  const railTaboolas = [];
  const wideTaboolas = [];

  // sort only Taboola modules into groups
  regionNames.forEach(regionName => {
    const regionModuleArray = pageRegions[regionName];
    const whichGroup = /rail/i.test(regionName) ? railTaboolas : wideTaboolas;

    // WEB-23859 Replace P2 ad unit with 1x1 Taboola unit.
    // If the 1x1 Taboola widget is placed in the page variant
    // to replace MW_Position2 on the page, DO NOT count this
    // module as a Taboola module and complete the doTaboolaHack.
    regionModuleArray.forEach(module => {
      const { commands } = module?.props?.configs || [];
      const is1x1Taboola = Array.isArray(commands) && commands[0]?.mode?.includes?.('1x1');

      if (!is1x1Taboola && module?.component === 'Taboola') {
        whichGroup.push(module);
      }
    });
  });

  // If there are already Taboola modules in
  // any of the regions, normalize their props
  // and return out early.
  //
  // NOTE: uses side effects via object references!
  if (railTaboolas.length || wideTaboolas.length) {
    railTaboolas.forEach(module => {
      normalizeModuleProps({ module, locale, isRail: true });
    });
    wideTaboolas.forEach(module => {
      normalizeModuleProps({ module, locale, isRail: false });
    });

    return;
  }

  // Otherwise, if we have no Taboolas,
  // add one to the main region (unless
  // there IS no main region, in which
  // case we bail out).
  if (!mainRegion || !mainRegion.push) return;

  // Also, ensure we never add Taboolas to an
  switch (pageKey) {
    // Apple alertDetails, because EXCEPTIONS
    case 'alertDetails':
      if (isAppleWidgetPartner(pageConfig)) return;
      break;
    // Allergy, because Claritin!
    case 'allergy':
      return;
    default:
  }

  const props = getTaboolaProps(pageKey, locale);
  const newModule = normalizeModuleProps({
    module: {
      component: 'Taboola',
      props,
    },
    locale,
    isRail: false,
  });

  // add Taboola to page
  mainRegion.push(newModule);
};

// HELPER
function isAppleWidgetPartner(pageConfig) {
  const { meta } = pageConfig;
  const { partner } = meta;

  return partner === 'apple_widget';
}
