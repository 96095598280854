import { createSelector } from 'reselect';
import { pageParamsSelector } from '@wxu/contexts/src/page/selectors';

export const urlPathStormTypeSelector = createSelector(
  pageParamsSelector,
  (pageParams) => pageParams?.stormType
);

export const urlPathStormNameSelector = createSelector(
  pageParamsSelector,
  (pageParams) => pageParams?.stormName
);
