import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isPlainObject from 'lodash/isPlainObject';
import toPlainObject from 'lodash/toPlainObject';
import sortBy from 'lodash/sortBy';
import mergeWith from 'lodash/mergeWith';
import { createSelector } from 'reselect';
import { pageHeadersSelector } from '@wxu/contexts/src/page/selectors';
import { createDeepEqualSelector } from '@wxu/contexts/src/redux-dal/selectors';

export type ExperimentName = string;
export type VariantName = string;
export type VariantPayload = {
  moneytree?: boolean;
  helios?: unknown; // no need to flesh this out here
  taboola?: TaboolaOverride;
};

export type ExperimentMap = Record<ExperimentName, ExperimentMapValue>;

export type ExperimentMapValue = {
  value: VariantName
  payloads?: Record<VariantName, VariantPayload>,
};

export type Experiment = {
  experimentName: string;
  variantName: string;
  variantPayload?: VariantPayload | null;
};

export type TaboolaOverride = {
  disabled?: boolean;
  lazyloadOffset?: number;
  placementSuffix?: string;
  containerSuffix?: string;
};

export const variationHeaderSelector = createSelector(
  pageHeadersSelector,
  (headers): ExperimentMap => {
    try {
      return parseTwcVariationHeader(headers?.['twc-variation'] ?? '');
    } catch (err) {
      return {};
    }
  },
);

export const experimentHeaderSelector = createSelector(
  pageHeadersSelector,
  (headers): ExperimentMap => {
    try {
      return parseTwcExperimentHeader(headers?.['twc-experiment'] ?? '');
    } catch (err) {
      return {};
    }
  },
);

// Growthbook is currently being evaluated. Its active experiments are passed thru via
// edgeworker in the twc-experiment header. Moonracer experiments is still active for
// now as well. Its active experiments continue to be passed thru via edgeworker in the
// twc-variation header. Both sets of active experiments are merged here. Moonracer
// experiments will be deprecated when a replacement system has been decided.
export const experimentMapSelector = createSelector(
  variationHeaderSelector,
  experimentHeaderSelector,
  (twcVariationExperiment, twcExperimentExperiment) => ({
    ...twcExperimentExperiment,
    ...twcVariationExperiment,
  }),
);

export const experimentsSelector = createSelector(
  experimentMapSelector,
  (experimentMap): Experiment[] => {
    try {
      const experiments = [];

      for (const [experimentName, obj] of Object.entries(experimentMap)) {
        const experiment = {
          experimentName,
          variantName: obj.value,
          variantPayload: obj.payloads?.[obj.value] ?? null,
        };

        experiments.push(experiment);
      }

      return sortBy(experiments, ['experimentName', 'variantName']);
    } catch (err) {
      return [];
    }
  },
);

export const experimentVariantPayloadSelector = createDeepEqualSelector(
  experimentsSelector,
  (experiments): VariantPayload => {
    const payload = {};

    for (const experiment of experiments) {
      let { variantPayload } = experiment ?? {};
      const { taboola } = variantPayload ?? {};

      if (!isNil(variantPayload?.taboola)) {
        const taboolaDefaults = {
          placementSuffix: ` - ${experiment.variantName}`,
          containerSuffix: `---${experiment.variantName}`,
        };

        variantPayload = {
          ...variantPayload,
          taboola: {
            ...taboolaDefaults,
            ...taboola,
          },
        };
      }

      mergeWith(payload, variantPayload);
    }

    return payload;
  },
);

export const experimentNamesSelector = createDeepEqualSelector(
  experimentsSelector,
  (experiments) => experiments.map((experiment) => experiment.experimentName),
);

export const experimentVariantNamesSelector = createDeepEqualSelector(
  experimentsSelector,
  (experiments) => experiments.map((experiment) => experiment.variantName),
);

export const experimentReportingKeysSelector = createDeepEqualSelector(
  experimentsSelector,
  (experiments) => experiments.map((experiment) => `${experiment.experimentName}_${experiment.variantName}`),
);

export const experimentNamesCsvSelector = createDeepEqualSelector(
  experimentNamesSelector,
  (experimentNames) => experimentNames.join(','),
);

export const experimentVariantNamesCsvSelector = createDeepEqualSelector(
  experimentVariantNamesSelector,
  (variantNames) => variantNames.join(','),
);

export const experimentReportingKeysCsvSelector = createDeepEqualSelector(
  experimentReportingKeysSelector,
  (reportingKeys) => reportingKeys.join(','),
);

export const taboolaOverridesSelector = createSelector(
  experimentVariantPayloadSelector,
  (variantPayload) => {
    const { taboola } = (variantPayload ?? {}) as VariantPayload;

    if (isNil(taboola)) return {};

    return {
      ...taboola,
    };
  }
);

function base64Decode(v: string): string {
  return __CLIENT__ ? window.atob(v) : Buffer.from(v, 'base64').toString();
}

function validateExperimentMap(value: unknown): asserts value is ExperimentMap {
  if (!isPlainObject(value)) throw new Error('experiment map must be plain object');

  for (const [experimentName, obj] of Object.entries(value)) {
    const { payloads = {}, value: variantName } = obj;
    const variantPayload = payloads[variantName] ?? {};

    if (typeof experimentName !== 'string' || !experimentName) throw new Error(`experiment name must be non-empty string (got ${experimentName})`);
    if (typeof variantName !== 'string' || !variantName) throw new Error(`variant name must be non-empty string (got ${variantName})`);
    if (!isNull(variantPayload) && !isPlainObject(variantPayload)) throw new Error('variant payload must be plain object or null');
  }
}

function parseTwcVariationHeader(value: string): ExperimentMap {
  if (!value) return {};

  const obj = JSON.parse(base64Decode(value));

  validateExperimentMap(obj);

  return obj;
}

function parseTwcExperimentHeader(value: string): ExperimentMap {
  if (!value) return {};

  const obj = JSON.parse(base64Decode(value));

  const e: ExperimentMap = {};

  for (const k of Object.keys(obj)) {
    const [experimentName, variantName] = k.split('__');
    const payload = toPlainObject(obj[k]);

    // experiments should be created with a name and variant name that are short and
    // contain only valid characters that are safe to send to GAM, ignore any unsafe
    // entries that do not follow this convention
    if (isValidReportingKey(`${experimentName}_${variantName}`)) {
      e[experimentName] = {
        value: variantName,
        payloads: {
          [variantName]: payload,
        },
      };
    }
  }

  validateExperimentMap(e);

  return e;
}

function isValidReportingKey(s: string): boolean {
  return s.length <= 36 && /^[a-z0-9_-]+$/i.test(s);
}
