import {
  get as getKey,
  set as setKey,
  del as delKey,
  clear as clearKeys,
  Store,
} from 'idb-keyval';
import { createLogger } from '@wxu/logger';
import {
  DB_NAME,
  NERF_DB_NAME,
  NERF_STORE_NAME,
  STORE_NAME,
} from './constants';

const logger = createLogger('web-storage:keyval');

export const wxuWebStore = __CLIENT__ ? new Store(DB_NAME, STORE_NAME) : {};
export const nerfWebStore = __CLIENT__ ? new Store(NERF_DB_NAME, NERF_STORE_NAME) : {};

/**
 * Get value at `key` from the store.
 * @param  {string}   key
 * @param  {Object}   [options]
 * @param  {Store}    [options.store]
 * @param  {boolean}  [options.isJson]
 * @return {Promise<*>}
 */
export async function get(key, { store = wxuWebStore, isJson = true } = {}) {
  try {
    const value = await getKey(key, store);

    if (isJson) {
      let jsonValue;

      try {
        jsonValue = JSON.parse(value);
      } catch (err) {
        jsonValue = undefined;
      }

      return jsonValue;
    }

    return value;
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

/**
 * Set `val` at `key` in the store.
 * @param  {string}       key
 * @param  {*}            val
 * @param  {Object}       [options]
 * @param  {Store}        [options.store]
 * @param  {boolean}      [options.isJson]
 * @return {Promise<void>}
 */
export async function set(key, val, { store = wxuWebStore, isJson = true } = {}) {
  let value = val;

  try {
    if (isJson) {
      value = JSON.stringify(val);
    }

    await setKey(key, value, store);
  } catch (err) {
    logger.error(err);
    throw err;
  }
}

/**
 * Delete a key from the store.
 * @param  {string}       key
 * @param  {Object}       [options]
 * @param  {Store}        [options.store]
 * @return {Promise<void>}
 */
export async function del(key, { store = wxuWebStore } = {}) {
  await delKey(key, store);
}

/**
 * Delete all keys in the store.
 * @param  {Object}       [options]
 * @param  {Store}        [options.store]
 * @return {Promise<void>}
 */
export async function clear({ store = wxuWebStore } = {}) {
  await clearKeys(store);
}
