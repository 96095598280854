import { createSelector } from 'reselect';
import { unitHeaderSelector } from '@wxu/contexts/src/page/selectors';
import { capitalize } from '@wxu/utilities/src/string';
import {
  unitsSystemByCode,
  unitsSystemByLocale,
} from '@wxu/units';
import { localeSelector } from './locale';

/**
 * Return the entire units system, based on the value of the `twc-unit` header.
 * When no `twc-unit` header is present, or is an unsupported value, units are
 * derived from the locale.
 */
export const unitsSystemSelector = createSelector(
  localeSelector,
  unitHeaderSelector,
  /**
   * @param {string} locale
   * @param {string} unitHeader
   * @returns {import('@wxu/units').UnitsSystem}
   */
  (locale, unitHeader) => {
    const unitsByCode = unitsSystemByCode(unitHeader);
    const unitsByLocale = unitsSystemByLocale(locale);

    return unitsByCode || unitsByLocale;
  },
);

/**
 * Return the units system's 1-character code,
 * per locale. E.g. 'e'
 */
export const unitsSystemCodeSelector = createSelector(
  unitsSystemSelector,
  system => system.code,
);

/**
 * Return the units system's human-readable name,
 * per locale. E.g. 'imperial'
 */
export const unitsSystemNameSelector = createSelector(
  unitsSystemSelector,
  system => system.name,
);

/**
 * Return the units system's human-readable name capitalized,
 * per locale. E.g. 'Imperial'
 */
export const unitsSystemNameCapitalizedSelector = createSelector(
  unitsSystemSelector,
  system => capitalize(system.name),
);

/**
 * Return the units temp unit, per locale. E.g. 'F'
 */
export const unitsSystemTempUnitSelector = createSelector(
  unitsSystemSelector,
  system => system.temp,
);

/**
 * Return the units speed unit, per locale. E.g. 'mph'
 */
export const unitsSystemSpeedUnitSelector = createSelector(
  unitsSystemSelector,
  system => system.speed,
);

/**
 * Return the units distance unit, per locale. E.g. 'mi'
 */
export const unitsSystemDistanceUnitSelector = createSelector(
  unitsSystemSelector,
  system => system.distance,
);

/**
 * Return the units accumulation unit, per locale. E.g. 'in'
 */
export const unitsSystemAccumulationUnitSelector = createSelector(
  unitsSystemSelector,
  system => system.accumulation,
);

/**
 * Return the units precip unit, per locale. E.g. 'in'
 */
export const unitsSystemPrecipUnitSelector = createSelector(
  unitsSystemSelector,
  system => system.precip,
);

/**
 * Return the units pressure unit, per locale. E.g. 'in'
 */
export const unitsSystemPressureUnitSelector = createSelector(
  unitsSystemSelector,
  system => system.pressure,
);
