
/**
 * NOTE: This package exists to provide simple numeric values. It should NOT
 * contain date calculation functions; that's what the @wxu/dates package is for.
 * Do NOT use this for "Date Math" traversing date boundaries! That won't always
 * work, so, CHECK YOURSELF BEFORE YOU WRECK YOURSELF.
 *
 * (For more information, consult ../README.md)
*/

// Days in a Week (the Beatles notwithstanding)
export const DAY_PER_WK = 7;

// Hours in a (standard) Day or Week
export const HR_PER_DAY = 24; // always 24; does not account for ST/DST boundaries
export const HR_PER_WK = DAY_PER_WK * HR_PER_DAY; // 7 * 24

// Minutes in an Hour, Day, or Week
export const MIN_PER_HR = 60;
export const MIN_PER_DAY = HR_PER_DAY * MIN_PER_HR; // 24 * 60
export const MIN_PER_WK = DAY_PER_WK * HR_PER_DAY * MIN_PER_HR; // 7 * 24 * 60

// Seconds in a Minute, Hour, Day, or Week
export const SEC_PER_MIN = 60;
export const SEC_PER_HR = MIN_PER_HR * SEC_PER_MIN; // 60 * 60
export const SEC_PER_DAY = HR_PER_DAY * MIN_PER_HR * SEC_PER_MIN; // 24 * 60 * 60
export const SEC_PER_WK = DAY_PER_WK * HR_PER_DAY * MIN_PER_HR * SEC_PER_MIN; // 7 * 24 * 60 * 60

// Milliseconds in a Second, Minute, Hour, Day, or Week
export const MS_PER_SEC = 1000;
export const MS_PER_MIN = SEC_PER_MIN * MS_PER_SEC; // 60 * 1000
export const MS_PER_HR = MIN_PER_HR * SEC_PER_MIN * MS_PER_SEC; // 60 * 60 * 1000
export const MS_PER_DAY = HR_PER_DAY * MIN_PER_HR * SEC_PER_MIN * MS_PER_SEC; // 24 * 60 * 60 * 1000
export const MS_PER_WK = DAY_PER_WK * HR_PER_DAY * MIN_PER_HR * SEC_PER_MIN * MS_PER_SEC; // 7 * 24 * 60 * 60 * 1000
