export const REHYDRATE_PREFIX = 'REHYDRATE/';
export const PERSIST_PENDING = 'PERSIST/PENDING';
export const PERSIST_COMPLETE = 'PERSIST/COMPLETE';
export const PERSIST_STATUS = 'persistStatus';
export const DB_NAME = 'wxu-web';
export const STORE_NAME = 'keyval';
export const LS_STORE_KEY = `${DB_NAME}/${STORE_NAME}`;

// NERF-related constants
export const NERF_DB_NAME = 'nerf-web';
export const NERF_STORE_NAME = 'keyval';
export const NERF_LS_STORE_KEY = `${NERF_DB_NAME}/${NERF_STORE_NAME}`;
