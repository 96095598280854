import { createLogger } from '@wxu/logger';
import {
  get, set, del, wxuWebStore,
} from './keyVal';
import {
  getItem, setItem, removeItem, getLocalStorageKey,
} from './localStorage';

const logger = createLogger('web-storage');

/**
 * Gets data from storage.
 * `localStorage` is the default storage device, however
 * `IndexedDb` is an alternative.
 * @param   {string}                      key
 * @param   {Object}                      [options]
 * @param   {string}                      [options.idbKey]
 * @param   {string}                      [options.lsKey]
 * @param   {import('idb-keyval').Store}  [options.store]
 * @param   {boolean}                     [options.isJson]
 * @param   {boolean}                     [options.indexedDb]
 * @return  {Promise<*>}
 */
export async function getFromStorage(key, {
  idbKey = key,
  lsKey = getLocalStorageKey(idbKey),
  store = wxuWebStore,
  isJson = true,
  indexedDb = false,
} = {}) {
  let data = null;

  if (indexedDb) {
    try {
      data = await get(idbKey, { store, isJson });
    } catch (err) {
      logger.debug(err, 'IndexedDb unavailable');
    }

    return data;
  }

  try {
    data = await getItem(lsKey, { isJson });
  } catch (err) {
    logger.debug(err, 'localStorage unavailable');
  }

  return data;
}

/**
 * Sets data to storage.
 * `localStorage` is the default storage device, however
 * `IndexedDb` is an alternative.
 * @param {string}                      key
 * @param {*}                           data
 * @param {Object}                      [options]
 * @param {string}                      [options.idbKey]
 * @param {string}                      [options.lsKey]
 * @param {import('idb-keyval').Store}  [options.store]
 * @param {boolean}                     [options.isJson]
 * @param {boolean}                     [options.indexedDb]
 */
export async function setToStorage(key, data, {
  idbKey = key,
  lsKey = getLocalStorageKey(idbKey),
  store = wxuWebStore,
  isJson = true,
  indexedDb = false,
} = {}) {
  if (indexedDb) {
    try {
      await set(idbKey, data, { store, isJson });
    } catch (err) {
      logger.debug(err, 'IndexedDb unavailable');
    }

    return;
  }

  try {
    await setItem(lsKey, data);
  } catch (lsErr) {
    logger.debug('localStorage unavailable');
  }
}

/**
 * Removes data from storage.
 * `localStorage` is the default storage device, however
 * `IndexedDb` is an alternative.
 * @param   {string}                      key
 * @param   {Object}                      [options]
 * @param   {string}                      [options.idbKey]
 * @param   {string}                      [options.lsKey]
 * @param   {import('idb-keyval').Store}  [options.store]
 * @param   {boolean}                     [options.indexedDb]
 * @return  {Promise<void>}
 */
export async function removeFromStorage(key, {
  idbKey = key,
  lsKey = getLocalStorageKey(idbKey),
  store = wxuWebStore,
  indexedDb = false,
} = {}) {
  if (indexedDb) {
    try {
      await del(idbKey, { store });
    } catch (err) {
      logger.debug(err, 'IndexedDb unavailable');
    }
  }

  try {
    await removeItem(lsKey);
  } catch (err) {
    logger.debug(err, 'localStorage unavailable');
  }
}
