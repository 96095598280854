export const SEVERE_WEATHER_ALERT_TRIGGERS = [
  {
    phenomena: 'TO',
    significance: 'W',
  },
  {
    phenomena: 'tornado',
    significance: 'W',
  },
  {
    phenomena: 'EW',
    significance: 'W',
  },
  {
    phenomena: 'SV',
    significance: 'W',
  },
  {
    phenomena: 'TSA',
    significance: 'W',
  },
  {
    phenomena: 'HU',
    significance: 'W',
  },
  {
    phenomena: 'hurricane',
    significance: 'W',
  },
  {
    phenomena: 'tsunami',
    significance: 'W',
  },
  {
    phenomena: 'TR',
    significance: 'W',
  },
  {
    phenomena: 'TI',
    significance: 'W',
  },
  {
    phenomena: 'TS',
    significance: 'W',
  },
  {
    phenomena: 'BZ',
    significance: 'W',
  },
  {
    phenomena: 'WS',
    significance: 'W',
  },
  {
    phenomena: 'LE',
    significance: 'W',
  },
  {
    phenomena: 'IS',
    significance: 'W',
  },
  {
    phenomena: 'SQ',
    significance: 'W',
  },
  {
    phenomena: 'HI',
    significance: 'A',
  },
  {
    phenomena: 'HU',
    significance: 'A',
  },
  {
    phenomena: 'TI',
    significance: 'A',
  },
  {
    phenomena: 'TR',
    significance: 'A',
  },
  {
    phenomena: 'TY',
    significance: 'A',
  },
  {
    phenomena: 'squall',
    significance: 'W',
  },
  {
    phenomena: 'stormSurge',
    significance: 'W',
  },
  {
    phenomena: 'tropStorm',
    significance: 'W',
  },
  {
    phenomena: 'thunderstorm',
    significance: 'W',
  },
  {
    phenomena: 'blizzard',
    significance: 'W',
  },
  {
    phenomena: 'winterStorm',
    significance: 'W',
  },
  {
    phenomena: 'HW',
    significance: 'E',
  },
  {
    phenomena: 'RF',
    significance: 'E',
  },
  {
    phenomena: 'SNF',
    significance: 'E',
  },
  {
    phenomena: 'SNS',
    significance: 'E',
  },
  {
    phenomena: 'SSG',
    significance: 'E',
  },
  {
    phenomena: 'WS',
    significance: 'E',
  },
  {
    phenomena: 'HU',
    significance: 'W',
  },
];
