const { env } = require('@wxu/env');
const pino = require('pino');
const {
  DEFAULT_LOG_LEVEL,
  LOG_LEVELS,
} = require('./constants');
const constants = require('./constants');

const LOG_LEVEL = env.LOG_LEVEL || DEFAULT_LOG_LEVEL;
const prettyPrint = env.NODE_ENV !== 'production';
const logger = pino({
  level: LOG_LEVEL,
  prettyPrint: prettyPrint ? { colorize: true } : undefined,
  serializers: {
    err: pino.stdSerializers.err,
    error: pino.stdSerializers.err,
  },
});

/**
 * Creates child logger with namespace and context
 * @param  {String}      namespace Identifies namespace context to use
 * @param  {Object}      context   Object that provides additional context for messaging
 * @return {pino.Logger}           Child logger
 */
function createLogger(namespace, context = {}) {
  return logger.child({
    namespace,
    ...context,
  });
}

function setLevel(level = DEFAULT_LOG_LEVEL) {
  if (logger && LOG_LEVELS.includes(level)) {
    logger.level = level;
  }
}

module.exports = {
  ...constants,
  createLogger,
  setLevel,
};
