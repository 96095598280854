import { createSelector } from 'reselect';
import Cookies from 'cookies-js';
import {
  pageKeySelector,
  partnerHeaderSelector,
  parSelector,
} from '@wxu/contexts/src/page/selectors';
import {
  PARTNERS_NAV_15_DAY,
  NAVIGABLE_PARTNERS_PAGE_KEYS,
  APPLE_PARTNER_DESIGNATION_WO_APPLE,
} from './constants';

/**
 * Selects the partner for a page.
 */
export const partnerSelector = createSelector(
  parSelector,
  partnerHeaderSelector,
  /**
   * @param  {string} par           value of par query param
   * @param  {string} partnerHeader value of twc-partner header
   * @return {string}
   */
  (par, partnerHeader) => {
    // Try the `par` query param
    if (par) return par;

    // Try to select partner from header, as that is set whenever
    // requests come from users with the partner cookie already set
    if (partnerHeader) return partnerHeader;

    // Check the cookie to ensure that server-rendered
    // partner-specific experiences aren't overwritten
    // during the client-render
    if (__CLIENT__) {
      const partner = Cookies.get('partner');

      return partner || '';
    }

    return '';
  },
);

// Returns whether or not the partner is Samsung
export const isSamsungPartnerSelector = createSelector(
  partnerSelector,
  (partner) => partner.toLowerCase().includes('samsung'),
);

// Returns whether or not the partner is Apple
export const isApplePartnerSelector = createSelector(
  partnerSelector,
  (partner) => partner.toLowerCase().includes('apple')
    || partner.toLowerCase().includes(APPLE_PARTNER_DESIGNATION_WO_APPLE),
);

export const samsungVietnamSalesCodeDetectionSelector = createSelector(
  partnerSelector,
  isSamsungPartnerSelector,
  (par, isSamsung) => {
    let isSamsungVietnamSalesCode = false;

    // if par value includes samsung and any of the sales codes, return true
    if (isSamsung) {
      const samsungVietnamSalesCodeList = ['XXV', 'XEV'];

      samsungVietnamSalesCodeList.forEach(samsungVietnamSalesCode => {
        if (par.includes(samsungVietnamSalesCode)) {
          isSamsungVietnamSalesCode = true;
        }
      });
    }

    return isSamsungVietnamSalesCode;
  }
);

export const samsungMoroccoSalesCodeDetectionSelector = createSelector(
  partnerSelector,
  isSamsungPartnerSelector,
  (par, isSamsung) => {
    let isSamsungMoroccoSalesCode = false;

    // if par value includes samsung and any of the sales codes, return true
    if (isSamsung) {
      const samsungMoroccoSalesCodeList = ['FWD', 'MAT', 'MED', 'MWD', 'SMG', 'WAN'];

      samsungMoroccoSalesCodeList.forEach(samsungMoroccoSalesCode => {
        if (par.includes(samsungMoroccoSalesCode)) {
          isSamsungMoroccoSalesCode = true;
        }
      });
    }

    return isSamsungMoroccoSalesCode;
  }
);

export const specialPartnerSelector = createSelector(
  partnerSelector,
  samsungVietnamSalesCodeDetectionSelector,
  (partner, samsungVietnam) => {
    if (samsungVietnam) return 'samsungVietnam';
    return partner;
  }
);

// Indicates whether or not the location name should be shown for oppo
export const allowOppoPartnerSelector = createSelector(
  partnerSelector,
  pageKeySelector,
  (partner, pageKey) => {
    if (partner === 'oppo_widget' && NAVIGABLE_PARTNERS_PAGE_KEYS.includes(pageKey)) return false;

    return true;
  }
);

// Should this partner use a "15 Day" or "10 Day" version
// of the "daily" page? Use this selector for choosing
// which link to have in navbars, which text to put on
// link-buttons to that page, and which behavior to use
// for modules on the "daily" page itself.
export const fifteenDayVariantSelector = createSelector(
  partnerSelector,
  (partner) => PARTNERS_NAV_15_DAY.includes(partner),
);
