/* General context selectors */

import {
  createDeepEqualSelector,
  getDalDataSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  contextListIdSelector,
  isFrontEndApiSelector,
  experienceQuerySelector,
} from '@wxu/contexts/src/page/selectors';
import { createSelector } from 'reselect';
// import { hackContexts } from './hackContexts';

/**
 * Gets the context list response data from dal state.
 */
export const contextListDataSelector = createDeepEqualSelector(
  getDalDataSelector,
  contextListIdSelector,
  (getDalData, id) => {
    const data = getDalData({
      name: 'getMewContextListUrlConfig',
      params: {
        id,
      },
    });

    return data;
  },
);

/**
 * Gets the contexts required by the context list.
 * These contexts are already sorted by the MEW
 * context_list API.
 */
export const contextListContextsSelector = createDeepEqualSelector(
  contextListDataSelector,
  (data) => {
    const rawContexts = data?.data?.contexts ?? [];
    const contexts = rawContexts.map(context => context.data);

    // TO-DO: Remove these two lines when MetricsApi implementation is
    // complete across all page services. Until then, you may enable
    // them during local development to "hack" the page's ContextList.
    // This is helpful while working on the new metrics implementation.
    // (You will also need to disable the OTHER return statement, of course!)
    // DO NOT ERASE UNTIL POST-JANUARY 2021!
    //
    // return hackContexts(contexts);

    return contexts;
  },
);

/**
 * Gets a dependency graph of contexts.
 * The returned graph is represented by a 2D
 * array, sorted by its sub-arrays of contexts,
 * ordered from least dependencies to most.
 * Contexts are bucketed into groups, based
 * on their level in the dependency chain.
 */
export const contextDependencyGraphSelector = createDeepEqualSelector(
  contextListContextsSelector,
  (contexts) => {
    /**
     * @type {Object[][]}
     */
    const dependencyGraph = contexts.reduce(
      /**
       * @param  {Object[][]} graph
       * @param  {Object}     context
       * @return {Object[][]} graph
       */
      (graph, context) => {
        let groupIndex = 0;

        let insertGroupIndex = 0;

        while (groupIndex < graph.length) {
          const contextGroup = graph[groupIndex];
          const dependencyIds = getDependencyIds(context);
          const hasDependencyInGroup = contextGroup.some(
            ctx => dependencyIds.includes(ctx.id)
          );

          if (hasDependencyInGroup) {
            insertGroupIndex = groupIndex + 1;
          }

          groupIndex++;
        }

        graph[insertGroupIndex] = graph[insertGroupIndex] || [];
        graph[insertGroupIndex].push(context);

        return graph;
      }, []
    );

    return dependencyGraph;
  }
);

/**
 * Digs into a context entity to find all its
 * dependencies. Returns an array of their ids.
 *
 * @param   {Object}    context
 * @returns {string[]}
 */
export function getDependencyIds(context) {
  const deps = context.relationships.contextDependencies.data;

  return deps.map(dep => dep.id);
}

export const shouldDisableContextSelector = createSelector(
  isFrontEndApiSelector,
  experienceQuerySelector,
  (isFrontEndApi, experience) => isFrontEndApi || ['ios', 'android', 'wu'].includes(experience)
);
