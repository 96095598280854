export const linkListId = '798ce33c-729e-4ec4-b726-8d9a5c3ab6eb';
export const language = 'en_US';

export const SITE_MODE_NORMAL = 'normal';
export const SITE_MODE_HYBRID = 'severe1';
export const SITE_MODE_SEVERE = 'severe2';

export enum SITE_MODE {
  normal = 'normal',
  hybrid = 'severe1',
  severe = 'severe2',
}
