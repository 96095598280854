import { PERSIST_STATUS } from '../constants';

/**
 * Returns a slice of state, filtered by the allowlist keys of sub-state.
 * @param  {Object}   state
 * @param  {string[]} allowlist
 * @return {Object}
 */
export function getAllowedState(state, allowlist) {
  let allowedState = state;
  const stateKeys = Object.keys(state);
  const blockList = [PERSIST_STATUS];

  // removed blocked keys first
  allowedState = stateKeys.reduce((newState, stateKey) => {
    if (!blockList.includes(stateKey)) {
      newState[stateKey] = state[stateKey];
    }

    return newState;
  }, {});

  // filter keys not allowed
  if (Array.isArray(allowlist) && allowlist.length) {
    allowedState = stateKeys.reduce((newState, stateKey) => {
      if (allowlist.includes(stateKey)) {
        newState[stateKey] = state[stateKey];
      }

      return newState;
    }, {});
  }

  return allowedState;
}
