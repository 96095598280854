import {
  TABOOLA_BASE_PROPS,
  TABOOLA_PAGE_TYPES,
} from './constants';

/**
 * getTaboolaProps(pageKey)
 *
 * Example: for Tenday Page, returns...
 *  {
 *    ...TABOOLA_BASE_PROPS,
 *    configs: {
 *      url: TABOOLA_LOADERS[domestic|international],
 *      scriptId: 'tb_loader_script',
 *      commands: [
 *        {
 *          mode: 'thumbnails-d',
 *          container: 'taboola-below-content-thumbnails-tenday',
 *          placement: 'Below Content Thumbnails - tenday',
 *          target_type: 'mix',
 *        },
 *        {
 *          category: 'auto',
 *        },
 *        {
 *          flush: true,
 *        },
 *      ],
 *    },
 *  },
 *
 * @param {*} pageKey
 *
 * @returns {*} the base-props for a non-rail Taboola module
 */
export const getTaboolaProps = (pageKey) => {
  const container = `taboola-below-content-thumbnails-${pageKey}`;
  const placement = `Below Content Thumbnails - ${pageKey}`;
  const taboolaPageType = TABOOLA_PAGE_TYPES[pageKey] || TABOOLA_PAGE_TYPES.default;

  return {
    ...TABOOLA_BASE_PROPS,
    id: container,
    configs: {
      scriptId: 'tb_loader_script',
      commands: [
        {
          mode: 'thumbnails-d',
          container,
          placement,
          target_type: 'mix',
        },
        {
          [taboolaPageType]: 'auto',
        },
        {
          flush: true,
        },
      ],
    },
  };
};
