import {
  TABOOLA_LOADERS,
} from './constants';

/**
 * normalizeModuleProps({ module, locale, isRail = false })
 *
 * Applied to all Taboola instances, artificial
 * and CMS-loaded, to ensure compatibility.
 *
 * @param {*} Obj function args
 * @param {*} Obj.module       object ref to module
 * @param {string} Obj.locale  string locale
 * @param {boolean} Obj.isRail is/isn't in the right-rail
 *
 * @returns {*} reference to the normalized module
 */
export const normalizeModuleProps = ({ module, locale, isRail = false }) => {
  const modeSuffix = isRail ? 'rr' : 'd';
  const loaderKey = (locale === 'en-US') ? 'domestic' : 'international';
  const { props } = module;

  // Default taboola to lazyload
  props.moduleLazyload = false;
  props.offsetBottom = props?.offsetBottom ?? 1000;

  // side effects are intentional
  if (props.configs) {
    props.configs.url = TABOOLA_LOADERS[loaderKey];

    if (props.configs.commands?.length) {
      props.configs.commands.forEach((cmdObj) => {
        if (cmdObj.container || cmdObj.placement) {
          cmdObj.mode = `thumbnails-${modeSuffix}`;
        }
      });
    }
  }

  return module; // can also return the modified reference
};
