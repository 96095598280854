declare global {
  interface Navigator {
    globalPrivacyControl?: boolean;
  }
}

/**
 * Some Browsers give users the ability to exercise certain privacy settings.
 *
 * @returns {boolean} - indicates whether the user has exercised the browser GPC setting.
 */
export const isBrowserOptOutSignalOn = () => (typeof window !== 'undefined')
&& !!window.navigator?.globalPrivacyControl;
