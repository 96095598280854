import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import type { TaboolaOverride } from '@wxu/contexts/src/ab-testing/selectors';

export function applyTaboolaOverrides(pageConfig, taboolaOverrides?: TaboolaOverride) {
  if (isEmpty(taboolaOverrides)) return;

  const { lazyloadOffset, placementSuffix, containerSuffix } = taboolaOverrides;

  const regionNames = Object.keys(pageConfig.regions);

  for (const regionName of regionNames) {
    const modules = pageConfig.regions[regionName];

    for (const module of modules) {
      if (module.component === 'Taboola') {
        const props = cloneDeep(module.props);

        if (!isNil(lazyloadOffset)) {
          props.moduleLazyload = true;
          props.offsetBottom = lazyloadOffset;
        }

        const commands = props.configs?.commands?.filter(c => c.container) ?? [];

        for (const command of commands) {
          command.container += containerSuffix ?? '';
          command.placement += placementSuffix ?? '';
          props.id = command.container;
        }

        Object.assign(module.props, props);
      }
    }
  }
}
