/**
 * Gets URL hash for the hourly/daily anchor link functionality
 *
 * @param      {string}   detailIndexHash     The string to look for in the URL Hash
 * * Returns the URL's hash string without the '#' and the index number appended to the URL's hash string
 * @return     {Object<number,string>}
 */
export const urlHash = (detailIndexHash) => {
  if (__CLIENT__) {
    let hashIndex;
    let hashId;

    const { hash } = window.location;

    if (hash.includes(detailIndexHash)) {
      const urlHashIndex = hash.replace(`#${detailIndexHash}`, '');

      hashIndex = parseInt(urlHashIndex, 10) || null;
      hashId = hash.slice(1) || null;
    }

    return {
      hashIndex,
      hashId,
    };
  }
};

export const getUrlQueryParams = ():{[key:string]:string} => {
  if (__CLIENT__) {
    // + is being decoded as ' ', so encode before using
    const urlSearchParams = new URLSearchParams(window.location.search.replace('+', '%2B'));
    const params = Object.fromEntries(urlSearchParams.entries());

    return params;
  }

  return {};
};

export const getUrlQueryParam = (paramName):string => {
  // + is being decoded as ' ', so encode before using
  const params = new Proxy(new URLSearchParams(window.location.search.replace('+', '%2B')), {
    get: (searchParams, prop:string) => searchParams.get(prop),
  });

  return params[paramName];
};
