import {
  createDeepEqualSelector,
  getDalDataSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  pageKeySelector,
  pageLocaleSelector,
  pageQueryLocaleSelector,
  isFrontEndApiSelector,
  isWuAppIdSelector,
  isLiteContentSelector,
} from '@wxu/contexts/src/page/selectors';
import {
  isSamsungPartnerSelector,
} from '@wxu/contexts/src/partner/selectors';
import {
  DEFAULT_LOCALE,
  LANGUAGE_FALLBACK,
} from '../constants';

/**
 * Used to validate a locale's format.
 */
export const validLocaleRegex = /^([a-z]{2}|[a-z]{2,3}[-_][A-Z]{2})$/;

/**
 *
 * @param {string} localeCode
 * @returns {string}
 */
function validatedLocale(localeCode) {
  if (!localeCode) return DEFAULT_LOCALE;

  if (!validLocaleRegex.test(localeCode)) {
    return '';
  }

  return localeCode.replace('_', '-');
}

export const requestedLocaleSelector = createDeepEqualSelector(
  pageLocaleSelector,
  pageQueryLocaleSelector,
  // give query param priority over URL param
  (pageLocale, queryLocale) => queryLocale || pageLocale || '',
);

/**
 * Get "proposed" locale requested in page params, if any;
 * defaults to 'en-US' if path doesn't specify a locale.
 *
 * @return {string}
 */
export const validatedLocaleSelector = createDeepEqualSelector(
  requestedLocaleSelector,
  pageKeySelector,
  (localeCode, pageKey) => {
    const locale = validatedLocale(localeCode);

    if (!locale) {
      return pageKey === 'notFound' ? DEFAULT_LOCALE : locale;
    }

    return locale;
  },
);

/**
 * Gets all the params needed for `getMewLocaleUrlConfig`.
 */
export const getMewLocaleUrlConfigParamsSelector = createDeepEqualSelector(
  validatedLocaleSelector,
  isFrontEndApiSelector,
  isWuAppIdSelector,
  (localeCode, isFrontEndApi, isWuAppId) => {
    let locale = localeCode;

    if (isFrontEndApi && isWuAppId && locale !== 'en-US') {
      // WEB-17392 and WEB-17393: Privacy Settings and Dsr Form give WU App users
      // international English for WU apps regardless of locale for non en-US.
      locale = 'en-GB';
    }

    return { locale };
  }
);

function fallbackLocale(localeCode) {
  if (!localeCode) return DEFAULT_LOCALE;

  const lang = localeCode.split('-')[0];
  const locale = LANGUAGE_FALLBACK[lang] || DEFAULT_LOCALE;

  return locale;
}

export const canUseFallbackLocaleSelector = createDeepEqualSelector(
  isFrontEndApiSelector,
  isLiteContentSelector,
  isSamsungPartnerSelector,
  pageKeySelector,
  pageQueryLocaleSelector,
  (isFrontEndApi, isLiteContent, isSamsung, pageKey, queryLocale) => isFrontEndApi
    || isLiteContent
    || isSamsung // remove once ALL fallback locales are supported
    || queryLocale
    // queryLocale is included as some requests include a locale as a URL
    // parameter, as well as an additional locale as a query parameter, and
    // this query parameter can be an unsupported locale. For those cases,
    // it is appropriate to fall-back to a supported locale.
    || pageKey === 'notFound'
);

// In case app's locale combination (language-Country Eg. ja-DE)) is not web supported
// fallback to predetermined fallback locales, if not use en-US as last resort.
export const fallbackLocaleSelector = createDeepEqualSelector(
  validatedLocaleSelector,
  (localeCode) => {
    const locale = fallbackLocale(localeCode);

    return locale;
  }
);

export const fallbackLocaleEntitiesUrlConfigParamsSelector = createDeepEqualSelector(
  fallbackLocaleSelector,
  (locale) => ({ locale }),
);

/**
 * Gets the `getMewLocaleUrlConfig` config object.
 */
export const getMewLocaleUrlConfigSelector = createDeepEqualSelector(
  getMewLocaleUrlConfigParamsSelector,
  params => ({
    name: 'getMewLocaleUrlConfig',
    params,
  })
);

/**
 * Gets the matched locale from MEW, and its
 * matching language.
 */
export const getMewLocaleUrlConfigDataSelector = createDeepEqualSelector(
  getDalDataSelector,
  getMewLocaleUrlConfigParamsSelector,
  canUseFallbackLocaleSelector,
  fallbackLocaleEntitiesUrlConfigParamsSelector,
  (getDalData, params, canUseFallback, fallbackParams) => {
    let data = getDalData({
      name: 'getMewLocaleUrlConfig',
      params,
    });

    const localeData = data?.data ?? null;

    // if locale is not valid, saga.js
    // makes a fallback locale call.
    if (!localeData && canUseFallback) {
      data = getDalData({
        name: 'getMewLocaleUrlConfig',
        params: fallbackParams,
      });
    }

    return data?.data;
  }
);

/**
 * Get the matched locale entity, if any.
 */
export const localeEntitySelector = createDeepEqualSelector(
  getMewLocaleUrlConfigDataSelector,
  (data) => {
    const localeEntity = data?.locale?.data ?? null;

    return localeEntity;
  },
);

/**
 * Gets the xx-XX form of the locale.
 */
export const localeSelector = createDeepEqualSelector(
  localeEntitySelector,
  /**
   * @param   {Object}  localeEntity
   * @return  {string}
   */
  localeEntity => localeEntity?.attributes?.localeCode ?? '',
);


/**
 * Gets the xx_XX form of the locale, used for DSX calls.
 */
export const dsxLocaleSelector = createDeepEqualSelector(
  localeSelector,
  locale => (locale || '').replace('-', '_'),
);

/**
 * Gets 2-letter country code, in uppercase, from the locale.
 */
export const countryCodeSelector = createDeepEqualSelector(
  localeSelector,
  (locale) => {
    const defaultCode = '';

    if (locale && locale.length) {
      const languageCountryPair = locale.split('-');

      return languageCountryPair.length > 1 ? languageCountryPair[1] : defaultCode;
    }

    return defaultCode;
  },
);

/**
 * True or False: Does this locale have a "site" enabled
 * with its own locale-specific home page? Formerly this
 * was called "being a GLS+ site."
 *
 * NOTE: This doesn't reflect whether the locale has a
 * Country Partner or not; that's handled elsewhere.
 */
export const isLocaleSiteEnabledSelector = createDeepEqualSelector(
  localeEntitySelector,
  /**
   * @param   {Object}  localeEntity
   * @return  {boolean}
   */
  localeEntity => localeEntity?.attributes?.isLocaleSiteEnabled ?? false,
);

/**
 * Get validated language entity, as included in the locale entity's configured language.
 */
export const languageEntitySelector = createDeepEqualSelector(
  getMewLocaleUrlConfigDataSelector,
  (data) => {
    const languageEntity = data?.language?.data ?? null;

    return languageEntity;
  },
);

/**
 * Gets the languageCode for the locale. (In `xx` or `xx-XX` form)
 * of this page.
 */
export const languageEntityCodeSelector = createDeepEqualSelector(
  languageEntitySelector,
  /**
   * @param   {Object}  languageEntity
   * @return  {string}
   */
  languageEntity => languageEntity?.attributes.languageCode ?? '',
);

/**
 * Gets the 2 character base language code for the locale of this page.
 */
export const languageCodeSelector = createDeepEqualSelector(
  languageEntityCodeSelector,
  languageEntityCode => languageEntityCode.split('-')[0],
);

/**
 * Gets the full name of the language for the locale
 * of this page (in English, which can then be used as
 * a translation key to translate it to other languages.)
 */
export const languageNameSelector = createDeepEqualSelector(
  languageEntitySelector,
  /**
   * @param   {Object}  languageEntity
   * @return  {string}
   */
  languageEntity => languageEntity?.attributes.languageName ?? '',
);

/**
 * True or False: Is the current page's language an RTL language?
 */
export const isRTLSelector = createDeepEqualSelector(
  languageEntitySelector,
  /**
   * @param   {Object}  languageEntity
   * @return  {boolean}
   */
  languageEntity => languageEntity?.attributes.isRtlLanguage ?? false,
);

export const isEnUsSelector = createDeepEqualSelector(
  localeSelector,
  (locale) => locale === 'en-US'
);

