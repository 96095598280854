/**
 * Check tiers by specific features
 */

export const isTierFeatureIncluded = (tierFeatures, feature) => {
  if (tierFeatures && tierFeatures.exclude.includes(feature)) {
    return false;
  } if ((tierFeatures && tierFeatures.include.includes(feature))
  || (tierFeatures && tierFeatures.include.includes('all'))) {
    return true;
  }
  return false;
};
