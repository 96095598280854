
import { isBrowserOptOutSignalOn } from '@wxu/browser-opt-out-signal';
import { getCIItem } from '@wxu/ci-cookie';
import { SEC_PER_DAY } from '@wxu/time-units';
import {
  getItem,
  removeItem,
  setItem,
} from '@wxu/web-storage';
import { areCookiesEnabled } from '@wxu/web-storage/src/areCookiesEnabled';
import { isLocalStorageAvailable } from '@wxu/web-storage/src/isAvailable';
import Cookies from 'cookies-js';
import { initDev } from '@wxu/browser-dev-tools';
import { judgeFeatureConfig } from './judgeFeatureConfig';
import {
  regimesPurposesConfigs,
  RegimeDefaultPurposes,
} from './configs/regimesPurposesConfigs';
import {
  EXEMPT,
  Regime,
  RESTRICTIVE_REGIMES,
  US_REGIMES,
} from './configs/regimesConfig';
import {
  ESSENTIAL_TECHNOLOGY,
  GEOGRAPHICALLY_RELEVANT_ADVERTISING,
  FUNCTIONAL_TECHNOLOGY,
  SALE_OF_DATA,
  Purpose,
  UserConsents,
} from './configs/purposesConfig';
import {
  featureConfigs,
  FeatureFlags,
} from './configs/featureConfigs';
import {
  CONSENTS_LS_KEY,
  GPC_LS_KEY,
  DPR_SDK_LOADED_EVENT,
} from './constants';
import {
  getUserConsentsChanged,
  validateAndCleanUpUserConsents,
  logger,
  racePromiseAgainstTimeout,
} from './utils';

declare global {
  interface Window {
    DprSdk: _DprSdk;
  }
}

interface AppInfo {
  id: string,
  version: string
}

interface UserConsentPayload {
  userConsents: UserConsents;
  userConsentsChanged: Purpose[],
  userId: string;
  appInfo: AppInfo;
  geoIPCountryCode: string;
  regime: Regime;
}

interface Init {
  setFeatureFlags: (featureFlag: FeatureFlags) => void;
  setUserConsents: (userConsents: UserConsents) => void;
  getUserId: () => string;
  logUserConsent: (payload: UserConsentPayload) => void;
  getApplicationInfo: () => AppInfo;
  getUserRegime: () => Regime;
  getIsUserLoggedIn: () => boolean;
}

/**
 * DprSdk is responsible for:
 *  * Determining and providing the consuming app if a feature
 *    can be used or not based on the featureConfig, userConsents and regime.
 *  * Adding and initialiazing a consent manager.
 *  * Provide method to store/set and retrieve userConsents.
 *  * Provide privacy metrics methods
 */
class _DprSdk {
  #initialized: boolean;

  #userRegime: Regime;

  #featureFlags: FeatureFlags;

  #regimeDefaultPurposesMap: RegimeDefaultPurposes;

  #userConsents: UserConsents;

  #userId: string;

  #isUserLoggedIn: boolean;

  #applicationInfo: AppInfo;

  #debugModeEnabled: boolean;

  // Since init can run without hooks, we queue all the hook calls when init runs.
  // When setHooks is called, we can run these hooks in the queue.
  #runHooksQueue: Array<() => void>;

  /**
   * Assigns DprSdk to window object.
   */
  constructor() {
    if (window?.top.DprSdk) {
      return;
    }

    window.top.DprSdk = this;

    // Needed to init logger level.
    if (__CLIENT__) {
      initDev();
    }
    this.#initialized = false;
    this.#userRegime = EXEMPT;
    this.#featureFlags = {};
    this.#regimeDefaultPurposesMap = {};
    this.#userConsents = {};
    this.#userId = undefined;
    this.#isUserLoggedIn = undefined;
    this.#applicationInfo = {
      id: null,
      version: null,
    };
    this.#runHooksQueue = [];
  }

  /********************************************************************
   * hooks - start
   *
   * These hooks are assigned from the consuming app.
   ********************************************************************/
  #hookSetFeatureFlags: Init['setFeatureFlags'];

  #hookSetUserConsents: Init['setUserConsents'];

  #hookLogUserConsent: Init['logUserConsent'];

  #hookGetApplicationInfo: Init['getApplicationInfo'];

  #hookGetUserRegime: Init['getUserRegime'];

  #hookGetUserId: Init['getUserId'];

  #hookGetIsUserLoggedIn: Init['getIsUserLoggedIn'];

  /********************************************************************
   * hooks - end
   ********************************************************************/

  /********************************************************************
   * Initialization Resolves and Promises - start
   ********************************************************************/

  /**
   * Resolves DprSdk.isInitialized
   */
  #resolveIsInitialized: () => void;

  /**
   * Resolves DprSdk.#isUserIdInitialized
   */
  #resolveIsUserIdInitialized: () => void;

  /**
   * Resolves DprSdk.#isUserConsentsInitialized
   */
  #resolveIsUserConsentsInitialized: () => void;

  /**
   * Resolves DprSdk.#isUserLoggedInInitialized
   */
  #resolveIsUserLoggedInInitialized: () => void;

  /**
   * Promise resolves when DprSdk.init has been completed
   */
  public isInitialized = new Promise<void>((resolve) => {
    this.#resolveIsInitialized = resolve;
  });

  /**
   * Promise resolves when DprSdk.#userId has been initialized
   */
  #isUserIdInitialized = new Promise<void>((resolve) => {
    this.#resolveIsUserIdInitialized = resolve;
  });

  /**
   * Promise resolves when DprSdk.#isUserLoggedIn has been initialized
   */
  #isUserLoggedInInitialized = new Promise<void>((resolve) => {
    this.#resolveIsUserLoggedInInitialized = resolve;
  });

  /**
   * Promise resolves when DprSdk.#userConsents has been initialized
   */
  public isUserConsentsInitialized = new Promise<void>((resolve) => {
    this.#resolveIsUserConsentsInitialized = resolve;
  });

  /********************************************************************
   * Initialization Resolves and Promises - end
   ********************************************************************/

  /**
   * Should be the first thing invoked in the consuming app.
   *  * Initializes:
   *    * userConsents
   *    * userRegime
   *    * featureFlags
   *    * appropiate consent manager,
   *    * regimeDefaultPurposes
   *    * userId
   *    * isUserLoggedIn
   *    * applicationInfo
   *  * Sets the usprivacy cookie
   */
  public async init({
    setFeatureFlags,
    setUserConsents,
    getUserId,
    logUserConsent,
    getApplicationInfo,
    getUserRegime,
    getIsUserLoggedIn,
  }: Init) {
    // Prevent initializing more than once.
    if (this.#initialized) return true;

    // For Helios shenanigans
    this.setHooks({
      setFeatureFlags,
      setUserConsents,
      getUserId,
      logUserConsent,
      getApplicationInfo,
      getUserRegime,
      getIsUserLoggedIn,
    });

    await this.#initUserRegime();

    await Promise.allSettled([
      this.#initApplicationInfo(),
      this.#initUserConsents(), // Depends on having this.#userRegime defined
    ]);

    this.#setIabSignal(); // Depends on having this.#userRegime, this.#userConsents

    /**
     * Depends on having defined:
     *   this.#userRegime;
     *   this.#userConsents;
     *   this.#regimeDefaultPurposesMap;
     *   this.#geoIPCountryCode;
     */
    await this.#calculateFeatureFlags();

    /**
     * Depends on having defined:
     *   this.#userRegime;
     *   this.#featureFlags;
     *   this.#userId;
     *   this.#isUserLoggedIn
     */
    await this.#initConsentManager();

    this.#initialized = true;
    this.#resolveIsInitialized();

    document.dispatchEvent(new Event(DPR_SDK_LOADED_EVENT));

    return true;
  }

  public async setHooks({
    setFeatureFlags,
    setUserConsents,
    getUserId,
    logUserConsent,
    getApplicationInfo,
    getUserRegime,
    getIsUserLoggedIn,
  }, {
    runHooks = false,
  } = {}) {
    // Set hooks to DprSdk.

    this.#hookGetApplicationInfo = getApplicationInfo;
    this.#hookGetUserRegime = getUserRegime;
    this.#hookSetUserConsents = setUserConsents;
    this.#hookSetFeatureFlags = setFeatureFlags;
    this.#hookLogUserConsent = logUserConsent;

    /**
     * hooks with init promises - start
     *
     * Initialize these hooks below as soon as possible that
     * have dependencies on their respective initialization promises.
     */
    if (getUserId) {
      this.#hookGetUserId = getUserId;
      this.#initUserId();
    }
    if (getIsUserLoggedIn) {
      this.#hookGetIsUserLoggedIn = getIsUserLoggedIn;
      this.#initIsUserLoggedIn();
    }
    /**
     * hooks with init promises - end
     */

    if (runHooks) {
      await this.isInitialized;

      await this.#runHooks();
    }
  }

  /********************************************************************
   * private inits - start
   *
   * These fields are for initializing internal data of the DprSdk instance.
   ********************************************************************/

  /**
   * If the hooks were not set during init, we queue all the hook calls and run it here.
   * This function will only be called by setHooks.
   *
   * For Helios shenanigans
   */
  async #runHooks() {
    const promises = [];

    logger.info('executing runHooks', this.#runHooksQueue);

    this.#runHooksQueue.forEach((hook) => {
      promises.push(hook());
    });

    await Promise.allSettled(promises);
    this.#runHooksQueue = [];
  }

  async #initApplicationInfo() {
    this.#applicationInfo = await this.#hookGetApplicationInfo();
  }

  async #initUserRegime() {
    this.#userRegime = await this.#hookGetUserRegime();
  }

  async #initIsUserLoggedIn() {
    if (typeof this.#hookGetIsUserLoggedIn === 'function') {
      this.#isUserLoggedIn = await this.#hookGetIsUserLoggedIn();

      if (typeof this.#isUserLoggedIn !== 'undefined') {
        this.#resolveIsUserLoggedInInitialized();
      }
    }
  }

  async #initUserId() {
    if (typeof this.#hookGetUserId === 'function') {
      this.#userId = await this.#hookGetUserId();

      if (typeof this.#userId !== 'undefined') {
        this.#resolveIsUserIdInitialized();
      }
    }
  }

  async #initUserConsents() {
    let initialUserConsents = {};

    const initializeUserConsentsFromLocalStorage = async () => {
      const userConsentsLocalStorage = await getItem(CONSENTS_LS_KEY) as UserConsents;
      const validatedUserConsentsLocalStorage = validateAndCleanUpUserConsents(userConsentsLocalStorage);

      if (validatedUserConsentsLocalStorage) {
        initialUserConsents = validatedUserConsentsLocalStorage;
      } else {
        // Reset or no userConsents in localStorage.
        await setItem(CONSENTS_LS_KEY, initialUserConsents);
      }

      this.#userConsents = {
        ...this.#userConsents, ...initialUserConsents,
      };

      if (typeof this.#hookSetUserConsents === 'function') {
        this.#hookSetUserConsents(this.#userConsents);
      } else {
        this.#runHooksQueue.push(() => this.#hookSetUserConsents(this.#userConsents));
      }
    };

    const honorGPCSignal = async () => {
      const regimesThatHonorGPCSignal: Regime[] = [
        ...US_REGIMES,
      ];

      if (regimesThatHonorGPCSignal.includes(this.#userRegime)) {
        const gpcEnabled = window?.navigator?.globalPrivacyControl;
        const gpcSettingLocalStorage = Boolean(await getItem(GPC_LS_KEY));

        if (gpcEnabled && !gpcSettingLocalStorage) {
          await setItem(GPC_LS_KEY, true);
        } else if (!gpcEnabled && gpcSettingLocalStorage) {
          // Prompt Confirmation of Change: gpc disabled and was previously enabled
          // Handled by PrivacyConsentModal
          await setItem('gpcConflict', true);
        } else if (gpcEnabled && gpcSettingLocalStorage) {
          await removeItem('gpcConflict');
        }

        const isSaleOfDataOptedIn = (
          this.getUserConsent(SALE_OF_DATA) === true || (
            this.getUserConsent(SALE_OF_DATA) === undefined && this.getRegimePurposeDefaultValue(SALE_OF_DATA)
          ));

        if (gpcEnabled && isSaleOfDataOptedIn) {
          await this.setUserConsents({
            [SALE_OF_DATA]: false,
          });
        }
      }
    };

    try {
      this.#initRegimeDefaultPurposesMap();
      await initializeUserConsentsFromLocalStorage();
      await honorGPCSignal();
    } catch (e) {
      logger.error(e);
    }

    this.#resolveIsUserConsentsInitialized();
  }

  #initRegimeDefaultPurposesMap() {
    const {
      purposes: currentRegimePurposesConfig = [],
    } = (regimesPurposesConfigs && regimesPurposesConfigs.find(
      regimeConfig => regimeConfig.name === this.#userRegime
    )) || {};

    this.#regimeDefaultPurposesMap = currentRegimePurposesConfig.reduce((accum, currPurposeObj) => {
      accum[currPurposeObj.name] = currPurposeObj.value;

      return accum;
    }, {});
  }

  async #initConsentManager() {
    if (RESTRICTIVE_REGIMES.includes(this.#userRegime as typeof RESTRICTIVE_REGIMES[number])) {
      const {
        showConsentManager,
        initializeSourcepointCMP,
      } = await import(/* webpackChunkName: "SourcePoint"  */ './managers/sourcepoint/src/index');

      this.showConsentManager = () => showConsentManager({ regime: this.#userRegime });

      await racePromiseAgainstTimeout({
        promise: this.#isUserLoggedInInitialized,
        maxTimeMs: 3000,
      });

      if (this.#isUserLoggedIn) {
        // SourcePoint CMP authId config depends on userId to be initialized.
        await racePromiseAgainstTimeout({
          promise: this.#isUserIdInitialized,
          maxTimeMs: 3000,
        });
      }

      await initializeSourcepointCMP({
        regime: this.#userRegime,
        countryCode: this.#geoIPCountryCode,
        consentCallback: this.setUserConsents.bind(this),
        vendorsCallback: this.setFeatureFlags.bind(this),
        userId: this.#userId,
        isUserLoggedIn: this.#isUserLoggedIn,
      });
    }
  }
  /********************************************************************
   * private inits - end
   ********************************************************************/

  /********************************************************************
   * private fields - start
   *
   * These private fields are for internal usage of the DprSdk instance only
   * and can not be altered or referenced by the consuming app.
   ********************************************************************/

  #setIabSignal() {
    const iabCookieVal = __CLIENT__ && Cookies.get('usprivacy');
    const ccpaVersion = '1';

    // For non-usa and non-usa-ccpa users.
    let iabCcpaString = `${ccpaVersion}---`;

    if (US_REGIMES.includes(this.#userRegime)) {
      const userWasGivenOptOutNotice = 'Y'; // Safely use yes since consuming apps
      // have requirement to show USA privacy data notice for us users on first visit.
      const userHasOptedOutSaleUSA = (
        isBrowserOptOutSignalOn() || this.getUserConsent(SALE_OF_DATA) === false
      ) ? 'Y' : 'N';
      const publisherIabAggrementSigned = 'N'; // Publisher signed IAB's limited service agreement.
      // Per Pavan pass N For Jan 1, 2020 MVP.

      iabCcpaString = `${ccpaVersion}${userWasGivenOptOutNotice}${userHasOptedOutSaleUSA}${publisherIabAggrementSigned}`;
    }

    if (iabCcpaString !== iabCookieVal && __CLIENT__ && areCookiesEnabled()) {
      const domain = window.location.host.includes('wunderground') ? '.wunderground.com'
        : '.weather.com';

      Cookies.set('usprivacy', iabCcpaString, {
        path: '/',
        domain,
        expires: 365 * SEC_PER_DAY,
      });
    }
  }

  get #geoIPCountryCode() {
    const geoIPCountryCode = getCIItem('TWC-GeoIP-Country') || '';

    return geoIPCountryCode;
  }

  async #logUserConsent(userConsentsChanged: Purpose[]): Promise<void> {
    await racePromiseAgainstTimeout({
      promise: this.#isUserIdInitialized,
      maxTimeMs: 3000,
    });

    const userConsentPayload = {
      userConsents: this.#userConsents,
      userConsentsChanged,
      userId: this.#userId,
      appInfo: this.#applicationInfo,
      geoIPCountryCode: this.#geoIPCountryCode,
      regime: this.#userRegime,
    };

    if (this.#hookLogUserConsent) {
      this.#hookLogUserConsent(userConsentPayload);
    } else {
      this.#runHooksQueue.push(async () => this.#hookLogUserConsent(userConsentPayload));
    }
  }
  /********************************************************************
   * private fields - end
   ********************************************************************/

  /********************************************************************
   * public fields - start
   *
   * These fields can be used in the consuming app.
   ********************************************************************/

  /**
   * Field to open Consent Manager if applicable.
   */
  public showConsentManager: () => void;

  /**
   *  Function to set user consent choices.
   *  It sets DprSdk.#userConsents and
   *  and updates "userConsents" in localStorage.
   *
   *  Examples on how to use in consuming app:
   *    userConsents are passed as an object with
   *    purpose pairs and choice <string, boolean>.
   *
   *    ```js
   *      DprSdk.setUserConsents({
   *        'functional-technology': true,
   *        'geographically-relevant-advertising': true,
   *        'interest-based-ads': false,
   *      });```
   *
   * @param  {(string|Object.<string, boolean>)} userConsents - new user consents object.
   *
   */
  public async setUserConsents(userConsents: UserConsents, options?: {
    skipLogUserConsent?: boolean;
  }) {
    try {
      const validatedUserConsents = validateAndCleanUpUserConsents(userConsents);

      if (!validatedUserConsents) {
        throw new Error(`Trying to set invalid user consents: [${userConsents}]`);
      }
      const userConsentsChanged = getUserConsentsChanged(validatedUserConsents, this.#userConsents);
      const shouldUpdateUserConsents = !!userConsentsChanged.length;

      if (shouldUpdateUserConsents) {
        // user has made a change to an user consent.

        this.#userConsents = {
          ...this.#userConsents, ...validatedUserConsents,
        };

        await Promise.allSettled([
          this.#hookSetUserConsents ? this.#hookSetUserConsents(this.#userConsents) : this.#runHooksQueue.push(async () => this.#hookSetUserConsents(this.#userConsents)),
          setItem(CONSENTS_LS_KEY, this.#userConsents),
          !(options?.skipLogUserConsent) && this.#logUserConsent(userConsentsChanged),
          this.#calculateFeatureFlags(),
          this.#setIabSignal(),
        ]);
      }
    } catch (e) {
      logger.error(e);
    }
  }

  async setFeatureFlags(
    newFeatureFlags: FeatureFlags,
  ) {
    this.#featureFlags = {
      ...this.#featureFlags, ...newFeatureFlags,
    };

    if (this.#hookSetFeatureFlags) {
      await this.#hookSetFeatureFlags(this.#featureFlags);
    } else {
      this.#runHooksQueue.push(async () => await this.#hookSetFeatureFlags(this.#featureFlags));
    }
  }

  async #calculateFeatureFlags() {
    const isLSAvailable = isLocalStorageAvailable();
    const areCookiesAvailable = areCookiesEnabled();

    const featureFlags = featureConfigs.reduce(
      /**
       * Processes a FeatureConfig a into a FeatureFlag
       */
      (accum: FeatureFlags, currentFeatureConfig) => {
        accum[currentFeatureConfig.name] = judgeFeatureConfig({
          regime: this.#userRegime,
          featureConfig: currentFeatureConfig,
          userPurposesChoicesMap: this.#userConsents,
          regimeDefaultPurposesMap: this.#regimeDefaultPurposesMap,
          isLocalStorageAvailable: isLSAvailable,
          areCookiesEnabled: areCookiesAvailable,
          debugMode: this.#debugModeEnabled || false,
          geoIPCountryCode: this.#geoIPCountryCode,
        });
        return accum;
      }, {}
    );

    await this.setFeatureFlags(featureFlags);
  }

  public getUserConsent(key: Purpose) {
    return this.#userConsents?.[key];
  }

  /**
   * Returns the user consent choice if it exists, or the regime purpose default value.
   */
  public getUserConsentWithDefaultValue(key: Purpose) {
    return this.#userConsents?.[key] ?? this.getRegimePurposeDefaultValue(key);
  }

  public getUserConsents() {
    return this.#userConsents;
  }

  public getRegimePurposeDefaultValue(purpose: Purpose) {
    return this.#regimeDefaultPurposesMap[purpose];
  }

  public getFeatureFlag(flag: string) {
    return this.#featureFlags[flag];
  }

  public getFeatureFlags() {
    return this.#featureFlags;
  }

  /**
   * For Metrics beacon event property called "priv",
   * requirements for gdpr consent opt ins string:
   *   Return restrictive regime opt ins concatenated.
   */
  public getGdprConsentOptInsString() {
    const consentOptInsArray: Purpose[] = [ESSENTIAL_TECHNOLOGY];
    // User is always implied "opted-in" for essential-technology.

    const GDPR_USER_CONSENTS: Purpose[] = [
      FUNCTIONAL_TECHNOLOGY,
      GEOGRAPHICALLY_RELEVANT_ADVERTISING,
    ];

    GDPR_USER_CONSENTS.forEach((currPurpose) => {
      const regimePurposeDefaultValue = this.#regimeDefaultPurposesMap[currPurpose];
      const purposeIsOfRegimeAndHasDefaultValue = typeof regimePurposeDefaultValue === 'boolean';
      const userChoice = this.getUserConsent(currPurpose);
      const userMadeChoice = typeof userChoice === 'boolean';

      if ((userMadeChoice && userChoice)
        || (purposeIsOfRegimeAndHasDefaultValue && regimePurposeDefaultValue)
        || (!purposeIsOfRegimeAndHasDefaultValue && !userMadeChoice)) {
        consentOptInsArray.push(currPurpose);
      }
    });

    if (consentOptInsArray.length === 1 && consentOptInsArray[0] === ESSENTIAL_TECHNOLOGY) {
      return ESSENTIAL_TECHNOLOGY;
    }

    const consentOptInsString = consentOptInsArray.join(',');

    return consentOptInsString;
  }

  /**
   * For Metrics beacon event property called "privProduct",
   * Use these ccpa privacy settings metrics requirements:
   *   For us regimes: CCPAoptin for active/implied opt-in or CCPAoptout for opt-out.
   *   For non-usa regime users: return "blank" for privProduct.
   *   If user has made a sale of data choice carry over to other regimes.
   */
  public getCcpaSaleOptInString() {
    const userHasSaleOfDataChoice = typeof this.#userConsents?.[SALE_OF_DATA] === 'boolean';

    if (US_REGIMES.includes(this.#userRegime) || userHasSaleOfDataChoice
    ) {
      if ((!userHasSaleOfDataChoice && this.#regimeDefaultPurposesMap[SALE_OF_DATA]
        || userHasSaleOfDataChoice && this.#userConsents[SALE_OF_DATA]) && !isBrowserOptOutSignalOn()) {
        return 'CCPAoptin';
      }

      return 'CCPAoptout';
    }

    return null;
  }

  public isRestrictiveRegime() {
    return RESTRICTIVE_REGIMES.includes(this.#userRegime);
  }

  public isUSRegime() {
    return US_REGIMES.includes(this.#userRegime);
  }

  public isPurposePartOfRegime({ purpose }) {
    if (typeof this.#regimeDefaultPurposesMap[purpose] === 'boolean') {
      return true;
    }
    return false;
  }

  public debugMode({
    showFeatureConfigs = false,
  } = {}) {
    this.#debugModeEnabled = true;
    console.debug('\n\n\n\n ----- DprSdk debug mode enabled ----- \n\n\n\n');

    if (showFeatureConfigs) {
      this.#calculateFeatureFlags();
    }

    console.debug(
      'initialized: ',
      this.#initialized,
      '\n userId:',
      this.#userId,
      '\n isUserLoggedIn:',
      this.#isUserLoggedIn,
      '\n regime:',
      this.#userRegime,
      '\n regime default purposes values mapping:',
      this.#regimeDefaultPurposesMap,
      '\n userConsents:',
      this.#userConsents,
      '\n featureFlags:',
      this.#featureFlags,
      '\n\n\n\n ----- DprSdk debug mode ended ----- \n\n\n\n'
    );
  }

  /********************************************************************
   * public fields - end
   ********************************************************************/
}

export const DprSdk = new _DprSdk();
