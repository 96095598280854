import { createAction } from 'redux-actions';
import {
  REHYDRATE_PREFIX,
  PERSIST_PENDING,
  PERSIST_COMPLETE,
} from './constants';

/**
 * Creates an action tracked for rehydration.
 * @param  {string} namespace
 */
export function createRehydrateAction(namespace) {
  const actionType = createRehydrateActionType(namespace);

  return createAction(actionType);
}

/**
 * Creates a rehydrate action type.
 * @param  {string} namespace
 * @return {string}
 */
export function createRehydrateActionType(namespace) {
  const actionType = `${REHYDRATE_PREFIX}${namespace}`;

  return actionType;
}

export const persistPending = createAction(PERSIST_PENDING);
export const persistComplete = createAction(PERSIST_COMPLETE);
