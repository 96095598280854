import { call, spawn } from 'redux-saga/effects';
import { persistSaga } from './persist';
import { rehydrateSaga } from './rehydrate';

/**
 * Persists and rehydrates redux state for any given reducerKey
 * @param {string}    reducerKey
 * @param {Object}    [options]
 * @param {string}    [options.key]
 * @param {string[]}  [options.allowlist] Sub-state keys to be persisted. If empty, persist all sub-state
 */
export function* persist(reducerKey, {
  key = reducerKey,
  allowlist = [],
} = {}) {
  yield call(rehydrateSaga, reducerKey, { key, allowlist });
  yield spawn(persistSaga, reducerKey, { key, allowlist });
}

export * from './persist';
export * from './rehydrate';
