import { createSelector } from 'reselect';
import {
  createDeepEqualSelector,
  getDalDataSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import { pageKeySelector } from '@wxu/contexts/src/page/selectors';
import {
  sunV3BaseUrlConfigParamsSelector,
} from './UrlConfig';
import {
  FluOutlookInsight,
  Insight,
  PrecipInsightSupplementType,
  PrecipInsight,
  SevereStormInsight,
  ActivityInsight,
  InsightTypes,
  ActivityConfig,
  ActivityConfigInsight,
} from '../../types/Insights.types';
import { ACTIVITY_TYPE_TO_INSIGHT_TYPE, STATIC_BASE_URL } from './constants';

/**
 * Generic Insights request
 */
export const getSunV3InsightsUrlConfigSelector = ({
  insightType,
  par,
}) => createDeepEqualSelector(
  sunV3BaseUrlConfigParamsSelector,
  (baseUrlConfigParams) => ({
    name: 'getSunV3WeatherInsightsUrlConfig',
    params: {
      ...baseUrlConfigParams,
      insightType,
      par,
    },
  })
);

export const getSunV3InsightsDataSelector = ({ insightType, par }) => createDeepEqualSelector(
  getSunV3InsightsUrlConfigSelector({ insightType, par }),
  getDalDataSelector,
  (insightUrlConfig, getDalData): Insight[] => getDalData(insightUrlConfig)
);

/***************************************************************************************
 * precipInsight Selectors
 */
export const precipInsightsUrlConfigSelector = getSunV3InsightsUrlConfigSelector({
  insightType: 'precipInsight',
  par: 'twc',
});

export const precipInsightsDataSelector = createDeepEqualSelector(
  precipInsightsUrlConfigSelector,
  getDalDataSelector,
  (precipInsightsUrlConfig, getDalData): PrecipInsight[] => {
    const dalData = getDalData(precipInsightsUrlConfig);

    return dalData?.length ? dalData : null;
  }
);

/***************************************************************************************
 * severeStormInsight Selectors
 */
export const severeStormInsightUrlConfigSelector = getSunV3InsightsUrlConfigSelector({
  insightType: 'severeStormInsight',
  par: 'twc',
});

export const severeStormInsightDataSelector = createDeepEqualSelector(
  severeStormInsightUrlConfigSelector,
  getDalDataSelector,
  (severeStormInsightUrlConfig, getDalData): SevereStormInsight => {
    const dalData = getDalData(severeStormInsightUrlConfig);

    return dalData?.length ? dalData[0] : null;
  }
);

export const severeStormInsightHeadlineSelector = createSelector(
  severeStormInsightDataSelector,
  (severeStormInsight): string => severeStormInsight?.insightHeadline?.[0] ?? ''
);

export const severeStormInsightTextLongSelector = createSelector(
  severeStormInsightDataSelector,
  (severeStormInsight): string => severeStormInsight?.insightTextLong?.[0] ?? ''
);

/***************************************************************************************
 * fluOutlookInsight Selectors
 */
export const fluOutlookInsightUrlConfigSelector = getSunV3InsightsUrlConfigSelector({
  insightType: 'fluInsight',
  par: 'twc',
});

export const fluOutlookInsightDataSelector = createDeepEqualSelector(
  fluOutlookInsightUrlConfigSelector,
  getDalDataSelector,
  (fluOutlookInsightUrlConfig, getDalData):FluOutlookInsight => {
    const dalData = getDalData(fluOutlookInsightUrlConfig);

    return dalData?.length ? dalData[0] : null;
  }
);

export const fluOutlookInsightHeadlineSelector = createSelector(
  fluOutlookInsightDataSelector,
  (fluOutlookInsight):string => fluOutlookInsight?.insightHeadline?.[0] ?? ''
);

export const fluOutlookInsightTextLongSelector = createSelector(
  fluOutlookInsightDataSelector,
  (fluOutlookInsight):string => fluOutlookInsight?.insightTextLong?.[0] ?? ''
);

/**
 * Filtering on the WWIR insights which should only apply to near term precip events
 */
export const wwirInsightSelector = createDeepEqualSelector(
  precipInsightsDataSelector,
  (precipInsightsData):PrecipInsight => {
    if (!precipInsightsData) return null;

    return precipInsightsData.find((insight) => insight?.supplement?.precipSource === 'wwir');
  }
);

export const wwirInsightHeadlineSelector = createSelector(
  wwirInsightSelector,
  (wwirInsight):string => wwirInsight?.insightHeadline?.[0] ?? ''
);

export const wwirInsightTextLongSelector = createSelector(
  wwirInsightSelector,
  (wwirInsight):string => wwirInsight?.insightTextLong?.[0] ?? ''
);

export const precipInsightSupplementSelector = createDeepEqualSelector(
  precipInsightsDataSelector,
  (precipInsightsData):PrecipInsightSupplementType => precipInsightsData?.[0]?.supplement ?? null
);

/***************************************************************************************
 * snowNearInsight Selectors
 */

export const snowNearInsightUrlConfigSelector = getSunV3InsightsUrlConfigSelector({
  insightType: 'snowNearInsight',
  par: 'twc',
});

export const snowNearInsightDataSelector = createDeepEqualSelector(
  snowNearInsightUrlConfigSelector,
  getDalDataSelector,
  (snowNearInsightUrlConfig, getDalData):PrecipInsight => {
    const dalData = getDalData(snowNearInsightUrlConfig);

    return dalData?.length ? dalData[0] : null;
  }
);

export const snowNearInsightSupplementSelector = createSelector(
  snowNearInsightDataSelector,
  (snowNearInsightData):PrecipInsightSupplementType => snowNearInsightData?.supplement ?? null
);

export const snowNearInsightTextLongSelector = createSelector(
  snowNearInsightDataSelector,
  (snowNearInsightData):string => snowNearInsightData?.insightTextLong?.[0] ?? ''
);

export const allInsightsUrlConfigSelector = getSunV3InsightsUrlConfigSelector({
  insightType: 'all',
  par: 'twc',
});

export const allInsightsDataSelector = createDeepEqualSelector(
  allInsightsUrlConfigSelector,
  getDalDataSelector,
  (allInsightsUrlConfig, getDalData): Insight[] => getDalData(allInsightsUrlConfig)
);

const findInsight = (insightType: InsightTypes, insights: Insight[]) => insights?.find(
  (insight) => insight?.insightType === insightType
);

export const getActivityConfigsInsightsSelector = (
  activityConfigs: ActivityConfig[]
) => createDeepEqualSelector(
  pageKeyActivityTypeSelector,
  allInsightsDataSelector,
  (pageKeyActivityType, insights) => activityConfigs?.reduce?.((currActivities, activityConfig) => {
    const configInsightType = activityConfig?.insightKey;
    const pageKeyInsightType = ACTIVITY_TYPE_TO_INSIGHT_TYPE[pageKeyActivityType];
    const shouldIncludeActivity = configInsightType !== pageKeyInsightType && configInsightType !== 'outsideActivityInsight';

    if (shouldIncludeActivity) {
      const insight = findInsight(configInsightType, insights) as ActivityInsight;
      const supplement = insight?.supplement;

      if (supplement) {
        const {
          id,
          icon,
          ctaTitle,
        } = activityConfig;

        currActivities.push({
          id,
          icon: `${STATIC_BASE_URL}/${icon}`,
          ctaTitle,
          supplement,
        });
      }
    }

    return currActivities;
  }, [] as ActivityConfigInsight[])
);

export const outsideActivityInsightSelector = createDeepEqualSelector(
  allInsightsDataSelector,
  (insights) => (insights.find((insight) => insight.insightType === 'outsideActivityInsight') as ActivityInsight)
);

export const getActivityTypeFromPageKey = (pageKey) => pageKey
  .replace(/.*([A-Z].+)$/, '$1')
  .toLowerCase();

/**
 * eg.
 * pageKey - activityHubTennis
*  pageKeyActivityType - tennis
 */
export const pageKeyActivityTypeSelector = createSelector(
  pageKeySelector,
  getActivityTypeFromPageKey
);

export const activityTypeInsightSelector = createDeepEqualSelector(
  pageKeyActivityTypeSelector,
  allInsightsDataSelector,
  (activityType, data): ActivityInsight => {
    if (!data) return null;

    let insightType = `${activityType}ActivityInsight`;

    if (insightType === 'golfActivityInsight') insightType = 'golfingActivityInsight';

    const insightTypeInsight = data?.find(
      (insight) => insight.insightType === insightType
    ) as ActivityInsight;

    if (insightTypeInsight) {
      return insightTypeInsight;
    }

    const runningActivityInsightFallback = data?.find(
      (insight) => insight.insightType === 'runningActivityInsight'
    ) as ActivityInsight;

    return runningActivityInsightFallback;
  }
);
