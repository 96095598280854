/**
 * Capitalize first character of string and returns
 *
 * @param string - The string to capitalize first character of
 * @return the string with first character capitalized
 */
export const capitalize = (string: string): string => string && string[0].toUpperCase() + string.slice(1);

/**
 * Remove all or only specified (if provided) HTML tags from string and return text content
 *
 * @param input - The input string containing HTML
 * @param tagNames - An array of tag names to be stripped (e.g., ['p', 'strong', 'em'])
 * @return The string with HTML tags removed
 */
export const stripHtmlTags = (input: string, tagNames?: string[]): string => {
  if (!input) return '';
  const regex = tagNames ? new RegExp(`</?(${tagNames.join('|')})[^>]*>`, 'gi') : /<\/?[^>]+(>|$)/g;

  return input.replace(regex, '');
};
