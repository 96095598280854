import { getEnv } from '@wxu/env';

export const STATIC_BASE_URL = `${getEnv('STATIC_API_PROTOCOL')}://${getEnv('STATIC_API_HOST')}`;
export const ACTIVITY_HUB_TRANSLATION_NAMESPACE = 'wxu-activity-hub';

export const ACTIVITY_TYPE_TO_INSIGHT_TYPE = {
  cycling: 'cyclingActivityInsight',
  hiking: 'hikingActivityInsight',
  golf: 'golfingActivityInsight',
  camping: 'campingActivityInsight',
  gardening: 'gardeningActivityInsight',
  running: 'runningActivityInsight',
  tennis: 'tennisActivityInsight',
};
