export const PARTNER_COOKIE_KEY = 'partner';
export const THEME_COOKIE_KEY = 'twc-theme';
export const PARTNERS_NAV_15_DAY = ['oppo_widget'];
export const NAVIGABLE_PARTNERS_PAGE_KEYS = ['tenday', 'today', 'hourly', 'radar'];
export const PARTNER_COOKIE_DEFAULT_DURATION = 30; // minutes
export const PARTNER_COOKIE_DURATION_MAP = {
  samsung: 120, // minutes, = 2 hours
  // apple:     // add other override
  // oppo:      // values as needed
  // transsion: // for other partners
  // oneplus:   // if not using default
};
export const APPLE_PARTNER_DESIGNATION_WO_APPLE = 'weatherapptodaywidget';
export const HAS_LOGGED_SAMSUNG_VISITED_STATUS = 'hasLoggedSamsungVisitedStatus';
