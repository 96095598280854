/**
 * Super Lightweight UUID generator
 *
 * Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
 * where each x is replaced with a random hexadecimal digit from 0 to f, and y
 * is replaced with a random hexadecimal digit from 8 to b.
 *
 * A prime use case for this is generation of unique UUIDs as keys for children
 * in JSX.
 *
 * https://gist.github.com/jed/982883
 *
 * @param  {Number} [a] A numeric placeholder for random hexadecimal calcs
 * @return {string}     A v4 UUID string
 */
export function uuid(a) {
  return a
    // if a placeholder was passed, return a random number from 0 to 15
    // unless uuid is 8, in which case return a random number from 8 to 11 in
    // hexadecimal
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    // or otherwise return a concatenated string:
    // 10000000 +
    // -1000 +
    // -4000 +
    // -8000 +
    // -10000000000,
    // replacing 0s with 1s and 8s with random hex
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}

export function randomHex0to15() {
  return Math.floor((Math.random() * 16)).toString(16);
}

/**
 *
 * Modify one character in the uuid following rules from uuid function.
 * Function can be used to alter a uuid instead of regenerating one with uuid()
 * which can be computation heavy.
 *
 * @param {string} id
 * @returns {string}
 */
export function tinkerUuid(id) {
  let charToChange = Math.floor(Math.random() * 32);

  switch (true) {
    case charToChange === 8: // first -
    case charToChange === 14: // 4 in uuid
    case charToChange === 19: // y in uuid
    case charToChange === 23: // last -
      charToChange++;
      break;
    case charToChange === 13: // - before 4
    case charToChange === 18: // - before y
      charToChange += 2;
      break;
    default:
  }

  return id.substr(0, charToChange) + randomHex0to15(id[charToChange]) + id.substr(charToChange + 1);
}
