import { detectLocalStorageAvailable, detectSessionStorageAvailable } from './detect';

let isAvailable = null;

export function isLocalStorageAvailable() {
  if (isAvailable === null) {
    isAvailable = detectLocalStorageAvailable();
  }
  return isAvailable;
}

export function isSessionStorageAvailable() {
  if (isAvailable === null) {
    isAvailable = detectSessionStorageAvailable();
  }
  return isAvailable;
}
