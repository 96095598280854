import Cookies from 'cookies-js';

/**
 * Returns full contents of ciCookie as object
 * @return {Object}
 */
export function getCICookieObj() {
  if (!__CLIENT__) {
    return;
  }
  const ciCookieStr = Cookies.get('ci') || '';
  const ciCookieObj = ciCookieStr.split('&').reduce((accumulator, item) => {
    const itemArr = item.split('=');

    // eslint-disable-next-line prefer-destructuring
    accumulator[itemArr[0]] = itemArr[1];
    return accumulator;
  }, {});

  return ciCookieObj;
}

/**
 * Returns one item from ciCookie by key
 * @return {string}
 */
export function getCIItem(key) {
  if (!__CLIENT__) {
    return;
  }

  return getCICookieObj()[key];
}
