import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getCICookieObj } from '@wxu/ci-cookie';
import { normalizeGeocode } from '@wxu/contexts/src/page-location/selectors';

/**
 * Gets selector for latitude from ci cookie
 * @return {Object}
 */
export const geoIPLatitudeSelector = createSelector(
  getCICookieObj,
  cookieData => get(cookieData, 'TWC-GeoIP-Lat', null)
);

/**
 * Gets selector for longitude from ci cookie
 * @return {Object}
 */
export const geoIPLongitudeSelector = createSelector(
  getCICookieObj,
  cookieData => get(cookieData, 'TWC-GeoIP-Long', null)
);

/**
 * Gets selector for lat,long from ci cookie
 * @return {Object}
 */
export const geoIPGeocodeSelector = createSelector(
  geoIPLatitudeSelector,
  geoIPLongitudeSelector,
  (lat, long) => {
    if (lat && long) {
      return normalizeGeocode(`${lat},${long}`);
    }
    return null;
  },
);

/**
 * Gets selector for GeoIP Country (2-letter code) from ci cookie
 * @return {Object}
 */
export const geoIPCountryCodeSelector = createSelector(
  getCICookieObj,
  cookieData => get(cookieData, 'TWC-GeoIP-Country', null)
);

/**
 * Gets selector for city from ci cookie
 * @return {Object}
 */
export const geoIPCitySelector = createSelector(
  getCICookieObj,
  cookieData => get(cookieData, 'TWC-GeoIP-City', null)
);

/**
 * Gets selector for region from ci cookie
 * @return {Object}
 */
export const geoIPRegionSelector = createSelector(
  getCICookieObj,
  cookieData => get(cookieData, 'TWC-GeoIP-Region', null)
);

/**
 * Gets selector for DMA from ci cookie
 * @return {Object}
 */
export const geoIPDmaSelector = createSelector(
  getCICookieObj,
  cookieData => get(cookieData, 'TWC-GeoIP-DMA', null)
);
