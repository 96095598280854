import { Store } from 'idb-keyval';
import {
  createLogger,
  setLevel,
  LOG_LEVELS,
  DEFAULT_LOG_LEVEL,
  DEBUG,
} from '@wxu/logger';
import { getFromStorage, setToStorage } from '@wxu/web-storage';
import { getUrlQueryParams } from '@wxu/url-utils/src';
import { transformedPageConfigSelector } from '@wxu/page-config/src/selectors';
import {
  DEV_SETTINGS_KEY,
  REDUX_LOGGING_KEY,
  LOG_LEVEL_KEY,
} from './constants';

const logger = createLogger('dev');

async function getDevSettings() {
  try {
    const devSettings = await getFromStorage(DEV_SETTINGS_KEY);

    return devSettings || {};
  } catch (err) {
    logger.error('Error getting', DEV_SETTINGS_KEY);
  }

  return {};
}

async function getDevSetting(key) {
  const devSettings = await getDevSettings();

  return devSettings[key];
}

async function setDevSetting(key, val) {
  try {
    const devSettings = await getDevSettings();

    devSettings[key] = val;
    await setToStorage(DEV_SETTINGS_KEY, devSettings);
  } catch (err) {
    logger.error('Error setting', DEV_SETTINGS_KEY);
  }
}

/**
 * Adding debug query option, to be able to debug browsers with localStorage disabled.
 */
export async function getReduxLogging() {
  const isDebugPresent = !!getUrlQueryParams()?.debug;
  const reduxLogging = isDebugPresent ? true : await getDevSetting(REDUX_LOGGING_KEY);

  if (reduxLogging === true) {
    return true;
  }

  return false;
}

export async function setReduxLogging(bool) {
  await setDevSetting(REDUX_LOGGING_KEY, bool);
  logger.debug('reduxLogging set to', await getReduxLogging());
}

export async function getLogLevel() {
  const logLevel = await getDevSetting(LOG_LEVEL_KEY);

  if (!logLevel) {
    return DEFAULT_LOG_LEVEL;
  }

  return logLevel;
}

export async function setLogLevel(level) {
  if (LOG_LEVELS?.includes(level)) {
    setLevel(level);
    await setDevSetting(LOG_LEVEL_KEY, level);

    logger.debug('logLevel set to', await getLogLevel());
  } else {
    logger.error('Invalid logLevel. Valid levels are:', LOG_LEVELS?.join(', '));
  }
}

export async function disableLogging() {
  await setLogLevel(DEFAULT_LOG_LEVEL);
  const logLevel = await getLogLevel();

  logger.debug('logLevel set to', logLevel);
}

export async function enableLogging() {
  await setLogLevel(DEBUG);
  const logLevel = await getLogLevel();

  logger.debug('logLevel set to', logLevel);
}

export async function disableReduxLogging() {
  await setReduxLogging(false);
  logger.debug('reduxLogging set to', await getReduxLogging());
}

export async function enableReduxLogging() {
  await enableLogging();
  await setReduxLogging(true);
  logger.debug('reduxLogging set to', await getReduxLogging());
}

export function getPageConfig() {
  const pageConfig = transformedPageConfigSelector(window.__data);

  return pageConfig;
}

export async function initDev() {
  const isDev = __DEV__ || false;
  const dev = {
    getLogLevel,
    setLogLevel,
    getReduxLogging,
    setReduxLogging,
    disableLogging,
    enableLogging,
    disableReduxLogging,
    enableReduxLogging,
    getPageConfig,
    getFromStorage,
    setToStorage,
    IdbStore: Store,
  };

  let logLevel = await getLogLevel();

  if (isDev || window.location.search.includes('debug')) {
    logLevel = 'debug';
    await setReduxLogging(true);
  }

  await setLogLevel(logLevel);
  window.dev = dev;

  return dev;
}
