import { createSelector } from 'reselect';
import { contextListContextsSelector } from '@wxu/contexts/src/selectors';
import {
  createDeepEqualSelector, dalSelector,
  getDalDataSelector,
} from '@wxu/contexts/src/redux-dal/selectors';
import {
  isLocaleSiteEnabledSelector,
  localeSelector,
} from '@wxu/contexts/src/i18n/selectors';
import { getSiteModeSelector } from '@wxu/contexts/src/site-mode/selectors';
import { SITE_MODE_NORMAL } from '@wxu/contexts/src/site-mode/constants';
import { weatherModeSelector } from '@wxu/contexts/src/weather-mode/selectors';
import { WEATHER_MODE_NORMAL } from '@wxu/contexts/src/weather-mode/constants';
import {
  pageKeySelector,
  deviceClassSelector,
  experienceQuerySelector,
  isFrontEndApiSelector,
  serverPageStateQuerySelector,
  pageSubscriptionTierSelector,
} from '@wxu/contexts/src/page/selectors';
import { partnerSelector } from '@wxu/contexts/src/partner/selectors';
import { isValidSubscriptionTier, subscriptionProductTier } from '@wxu/contexts/src/subscription/selectors';
import { SUBSCRIPTION_TIER_NONE } from '@wxu/contexts/src/subscription/constants';
import {
  urlPathStormTypeSelector,
  urlPathStormNameSelector,
} from '@wxu/contexts/src/stormPage/selectors';
import {
  moduleInstanceConfigSelector,
  moduleInstanceIdSelector,
} from '@wxu/module-instance-middleware/src/selectors';
import { taboolaOverridesSelector } from '@wxu/contexts/src/ab-testing';
import { transformPageConfig } from './transformPageConfig';
import { experimentSelector } from '@wxu/contexts/src/page/selectors/pageSelectors';

export const modulePositionSelector = (id) => createSelector(dalSelector, (dal) => {
  const pageConfig = dal?.getMewPageConfigUrlConfig || {};
  const firstKey = Object?.keys(pageConfig)?.[0];
  const moduleInstances = pageConfig?.[firstKey]?.data?.data?.relationships?.module_instances?.data;
  const moduleMeta = moduleInstances?.find(module => module?.id === id)?.meta;
  const modulePosition = `${moduleMeta?.region}-${moduleMeta?.weight}`;

  return modulePosition;
});
export const contextNamesSelector = createSelector(
  contextListContextsSelector,
  (contexts) => {
    const names = contexts.map(context => context.attributes.name);

    return names;
  },
);

export const pageConfigLocaleSelector = createSelector(
  isLocaleSiteEnabledSelector,
  localeSelector,
  (isLocaleSiteEnabled, locale) => {
    if (isLocaleSiteEnabled) {
      return locale;
    }

    return 'gls';
  },
);

export const pageConfigDeviceClassSelector = createSelector(
  deviceClassSelector,
  (deviceClass) => {
    switch (deviceClass) {
      case 'desktop':
        return deviceClass;
      case 'mobile':
      case 'tablet':
      case 'bot':
        return 'mobile';
      default:
        return '';
    }
  },
);

export const pageConfigPartnerSelector = createSelector(
  partnerSelector,
  (partner) => {
    const defaultPartner = '';
    // Mapping of partner patterns to partner values.
    // These are the only values used to query page configs.
    const allowList = {
      weatherapptodaywidget: 'apple_widget',
      apple_: 'apple_widget',
      samsung: 'samsung_widget',
      oppo_: 'oppo_widget',
      transsion: 'transsion',
      oneplus: 'oneplus',
    };

    for (const [partnerPattern, partnerValue] of Object.entries(allowList)) {
      if (partner.toLowerCase().includes(partnerPattern)) {
        return partnerValue;
      }
    }

    return defaultPartner;
  },
);

export const pageConfigExperienceSelector = createSelector(
  experienceQuerySelector,
  pageKeySelector,
  (experience, pageKey) => {
    const allowedExperiences = [
      'android',
      'ios',
      'wu',
      'wu-android',
      'wu-ios',
      'wx',
      'support',
    ];

    switch (true) {
      case allowedExperiences.includes(experience):
        return experience;
      case pageKey === 'covid19':
      case pageKey === 'covid19map':
        return 'wx';
      default:
        return '';
    }
  },
);

export const pageConfigWeatherModeSelector = createSelector(
  weatherModeSelector,
  pageKeySelector,
  (weatherMode, pageKey) => {
    // Don't include weatherMode in page config
    // query when it's normal.
    if (weatherMode === WEATHER_MODE_NORMAL) return '';

    // These are the only pages which use weatherMode
    const allowList = [
      'today',
      'hourly',
      'tenday',
    ];

    if (allowList.includes(pageKey)) return weatherMode;

    return '';
  },
);

export const pageConfigSiteModeSelector = createSelector(
  getSiteModeSelector(),
  pageKeySelector,
  (siteMode, pageKey) => {
    // Don't include siteMode in page config
    // query when it's normal.
    if (siteMode === SITE_MODE_NORMAL) return '';

    // These are the only pages which use siteMode.
    const allowList = [
      'home',
    ];

    if (allowList.includes(pageKey)) return siteMode;

    return '';
  },
);

export const pageConfigSubscriptionSelector = createSelector(
  pageSubscriptionTierSelector,
  (subscriptionTier) => {
    const tier = subscriptionProductTier(subscriptionTier);

    if (!isValidSubscriptionTier(tier)) return SUBSCRIPTION_TIER_NONE;
    return tier;
  }
);

/**
 * Gets the context params needed for the page config.
 * Each value in the returned object must have a value; the default value for each key
 * should match the default value for the context in the page config
 * (e.g. the default for `connectionSpeed` is `4g`)
 * @param  {Object} state
 * @return {Object<string,string>}
 */
export const pageConfigContextsSelector = (state) => {
  const contexts = {
    localeContext: pageConfigLocaleSelector(state),
    deviceClass: pageConfigDeviceClassSelector(state),
    siteMode: pageConfigSiteModeSelector(state),
    weatherMode: pageConfigWeatherModeSelector(state),
    partner: pageConfigPartnerSelector(state),
    experience: pageConfigExperienceSelector(state),
    subscription: pageConfigSubscriptionSelector(state),
    stormType: urlPathStormTypeSelector(state),
    stormName: urlPathStormNameSelector(state),
    experiment: experimentSelector(state),
  };

  // Filter out falsy values from contexts.
  const filteredContexts = Object.keys(contexts).reduce((accum, name) => {
    if (contexts[name]) {
      accum[name] = contexts[name];
    }

    return accum;
  }, {});

  return filteredContexts;
};

export const pageKeyToContextsSelector = createDeepEqualSelector(
  pageKeySelector,
  pageConfigContextsSelector,
  (pageKey, contexts) => {
    // Maps pageKeys to the only contexts
    // each pageKey uses for page config queries.
    // Only add pageKeys which are not
    // compatible with the default page config
    // contexts - e.g. dsrGlossary requires zero
    // contexts for page config queries, and
    // pageConfigContextsSelector returns at
    // least localeContext and deviceClass.
    const pageKeysToContextKeysOverride = {
      accessibilityStatementApi: [],
      dataCategory: ['deviceClass'],
      dataDefinitions: ['deviceClass'],
      dataVendorsApi: ['experience'],
      dataRights: ['deviceClass'],
      dsrCategory: [],
      dsrFormApi: [],
      dsrGlossary: [],
      kaiOSAirQuality: [],
      privacySettings: ['deviceClass'],
      privacySettingsApi: ['experience'],
    };

    if (pageKeysToContextKeysOverride[pageKey]) {
      // pageKey has its own context keys defined
      // to override the contexts mapping.
      const pageKeyContextKeys = Array.isArray(pageKeysToContextKeysOverride[pageKey])
        && pageKeysToContextKeysOverride[pageKey] || [];

      const pageKeyToContexts = pageKeyContextKeys
        && pageKeyContextKeys.reduce((accum, currKey) => {
          if (contexts[currKey]) {
            accum[currKey] = contexts[currKey];
          }

          return accum;
        }, {});

      return pageKeyToContexts;
    }

    return contexts;
  }
);

export const getMewPageConfigUrlConfigParamsSelector = createDeepEqualSelector(
  pageKeySelector,
  pageKeyToContextsSelector,
  (pageKey, contexts) => {
    const params = {
      pageKey,
      contexts,
    };

    return params;
  },
);

export const getMewPageConfigUrlConfigSelector = createDeepEqualSelector(
  getMewPageConfigUrlConfigParamsSelector,
  params => ({
    name: 'getMewPageConfigUrlConfig',
    params,
  }),
);

export const pageConfigSelector = createDeepEqualSelector(
  getDalDataSelector,
  getMewPageConfigUrlConfigSelector,
  (getDalData, config) => {
    const data = getDalData(config);

    return data;
  },
);

export const getModuleConfigSelector = searchString => createDeepEqualSelector(
  pageConfigSelector,
  (pageConfig) => {
    const included = pageConfig?.included ?? [];
    const moduleData = included.find(el => el?.attributes?.name?.includes(searchString));

    return moduleData?.attributes?.configuration;
  }
);

export const getModuleConfigByUuidSelector = uuid => createDeepEqualSelector(
  pageConfigSelector,
  (pageConfig) => {
    const included = pageConfig?.included ?? [];
    const moduleData = included.find(el => el?.id?.includes(uuid));

    return moduleData?.attributes?.configuration;
  }
);

export const moduleServicePageConfigSelector = createSelector(
  serverPageStateQuerySelector,
  moduleInstanceConfigSelector,
  moduleInstanceIdSelector,
  (pageQueryParams, moduleInstanceConfig, moduleInstanceId) => {
    const componentFromQueryParams = pageQueryParams?.name ?? '';
    // hard code uuid
    const uuidForModule = moduleInstanceId || pageQueryParams?.uuid || '412206f5-fadc-4564-a7b8-9a7cb141c5b9';
    const component = componentFromQueryParams === 'WxuCVSwidget' ? 'CvsWidget' : componentFromQueryParams;
    const propsFromQueryParams = pageQueryParams?.props ?? null;
    const props = {
      ...(propsFromQueryParams ? JSON.parse(propsFromQueryParams) : {}),
      // Default config due to when launching out module service originally, we gave the CVS team
      // the url without props query parameter. This is work around to allow default props for
      // ?name=CvsWidget without passing the props query parameter.
      ...(component === 'CvsWidget' ? {
        placeId: '303b0e8e1241fc4aa6917782fcb6acc5e397301c948a6bda6c311c30e4c5f84f',
        cvsHref: 'https://www.cvs.com',
        twcHref: 'https://weather.com',
        moreFluInfoLink: 'https://www.cvs.com/immunizations/flu',
      } : {}),
      ...(moduleInstanceId ? moduleInstanceConfig : {}),
    };
    const meta = {};
    const layout = pageQueryParams?.layout ? pageQueryParams?.layout : 'ModuleService';
    const regions = {
      main: [
        {
          component,
          props: {
            id: component,
            ttl: 0,
            uuid: uuidForModule,
            ...props,
          },
        },
      ],
    };

    return {
      meta,
      layout,
      regions,
    };
  },
);

/**
 * @typedef {Object} PageConfig
 * @property {string} layout
 * @property {Object<string,Object[]>} regions
 * @property {Object} meta
 */

/**
 * Restructures page config for easier access to modules/layouts and their props
 *
 * @returns {PageConfig}
 */
export const transformedPageConfigSelector = createSelector(
  pageConfigSelector,
  moduleServicePageConfigSelector,
  localeSelector,
  pageKeySelector,
  isFrontEndApiSelector,
  deviceClassSelector,
  taboolaOverridesSelector,
  (config, moduleServiceConfig, locale, pageKey, isFrontEndApi, deviceClass, taboolaOverrides) => {
    if (pageKey === 'moduleService') {
      return moduleServiceConfig;
    }

    return transformPageConfig(
      config,
      {
        isFrontEndApi,
        locale,
        pageKey,
        deviceClass,
        taboolaOverrides,
      },
    );
  },
);

export const regionsSelector = createSelector(
  transformedPageConfigSelector,
  config => config?.regions ?? null,
);

/**
 * Get the metrics page code.
 */
export const pageCodeSelector = createSelector(
  transformedPageConfigSelector,
  config => config?.meta?.page_code ?? '',
);

export const pageConfigAttributesSelector = createSelector(
  transformedPageConfigSelector,
  config => config?.meta ?? {},
);

export const pageConfigAdsSelector = createDeepEqualSelector(
  pageConfigAttributesSelector,
  pageAttributes => pageAttributes?.ads ?? {},
);

export const moduleNamesSelector = createSelector(
  transformedPageConfigSelector,
  (pageConfig) => {
    const regions = pageConfig?.regions ?? {};
    const regionNames = Object.keys(regions);
    // Use Set to only store unique module names
    const moduleNamesCollector = new Set();

    regionNames.forEach((regionName) => {
      const regionModules = regions[regionName] || [];

      regionModules.forEach(({ component }) => {
        moduleNamesCollector.add(component);
      });
    });

    const moduleNames = [...moduleNamesCollector];

    return moduleNames;
  },
);

export const isExperienceWebRequestSelector = createSelector(
  pageConfigExperienceSelector,
  experience => ['wx', 'wu'].includes(experience)
);

export const isExperienceWuWebSelector = createSelector(
  pageConfigExperienceSelector,
  experience => experience === 'wu'
);

export const isAppPageConfigSelector = createSelector(
  pageConfigExperienceSelector,
  experience => ['ios', 'android'].includes(experience)
);

export const isWuAppPageConfigSelector = createSelector(
  pageConfigExperienceSelector,
  experience => ['wu-ios', 'wu-android'].includes(experience)
);

export const appsPageConfigExperienceSelector = createSelector(
  isAppPageConfigSelector,
  isWuAppPageConfigSelector,
  (wxApps, wuApps) => {
    switch (true) {
      case !!wxApps: return 'wx-apps';
      case !!wuApps: return 'wu-apps';
      default: return null;
    }
  },
);
