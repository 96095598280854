import {
  UserConsents,
  Purpose,
} from './configs/purposesConfig';
import {
  EXEMPT,
  GDPR,
  Regime,
} from './configs/regimesConfig';
import {
  FeatureConfig,
} from './configs/featureConfigs';
import {
  RegimeDefaultPurposes,
} from './configs/regimesPurposesConfigs';

export function judgeFeatureConfig({
  regime = EXEMPT,
  featureConfig,
  userPurposesChoicesMap = {},
  regimeDefaultPurposesMap = {},
  debugMode = false,
  isLocalStorageAvailable = false,
  areCookiesEnabled = false,
  geoIPCountryCode,
}:
{
  regime: Regime,
  featureConfig: FeatureConfig,
  userPurposesChoicesMap: UserConsents,
  regimeDefaultPurposesMap: RegimeDefaultPurposes,
  debugMode?: boolean,
  isLocalStorageAvailable: boolean,
  areCookiesEnabled: boolean,
  geoIPCountryCode: string,
}) {
  try {
    if (debugMode) {
      console.debug(`featureConfig: ${featureConfig && featureConfig.name}: `, featureConfig);
    }
    if (!featureConfig) return true;

    const {
      name,
      blockRegimes = [],
      enableRegimes = [],
      purposes: featurePurposes = [],
      honorUserChoicesOverRegimeDefaults,
      honorOtherRegimesOptOut = true,
      honorOtherRegimesOptIn = true,
    } = featureConfig || {};

    // WEB-20514: npa=1 should be appended for every ad call that is generated
    // within the country of Serbia.
    if (shouldEnableNPAForSerbiaGDPR({ name, regime, geoIPCountryCode })) return false;

    const configOverrideResult = evaluateConfigOverride({ regime, blockRegimes, enableRegimes });

    if (typeof configOverrideResult === 'boolean') return configOverrideResult;

    if (!isLocalStorageAvailable || !areCookiesEnabled) return false;

    // If feature config doesn't specify purposes use it.
    if (!featurePurposes && featurePurposes.length < 1) return true;

    // If userPurposesChoicesMap is not an object
    // reset to an empty object.
    if (typeof userPurposesChoicesMap !== 'object'
      || userPurposesChoicesMap === null) userPurposesChoicesMap = {};

    const hasUserMadeAnyChoiceFromCurrentRegime = determinesIfUserMadeAnyChoiceBasedOnRegime({
      featurePurposes,
      userPurposesChoicesMap,
      regimeDefaultPurposesMap,
      checkChoicesFromCurrentRegime: true,
      checkChoicesFromAnotherRegime: false,
    });
    const hasUserMadeAnyChoiceFromAnotherRegime = determinesIfUserMadeAnyChoiceBasedOnRegime({
      featurePurposes,
      userPurposesChoicesMap,
      regimeDefaultPurposesMap,
      checkChoicesFromCurrentRegime: false,
      checkChoicesFromAnotherRegime: true,
    });

    const hasUserMadeAnyChoice = hasUserMadeAnyChoiceFromCurrentRegime
      || hasUserMadeAnyChoiceFromAnotherRegime;

    return featurePurposes.every((currPurposeKey) => {
      const userPurposeChoice = userPurposesChoicesMap[currPurposeKey];
      const userHasMadeCurrentPurposeChoice = typeof userPurposeChoice === 'boolean';
      const currentPurposeDefaultValue = regimeDefaultPurposesMap[currPurposeKey];
      const purposeIsOfRegimeAndHasDefaultValue = typeof currentPurposeDefaultValue === 'boolean';

      if (userHasMadeCurrentPurposeChoice) {
        /**
         * User has made a choice for purpose.
         *
         * Conditions to respect current purpose choice:
         *   1. When the current purpose is part of regime, respect it.
         *   2. When the current purpose is not part of regime, and honorOtherRegimesOptOut is false
         *      respect it if user has opted out for the current purpose.
         *   3. When the current purpose is not part of regime, and honorOtherRegimesOptOut is true
         *      respect it if user has opted in for the current purpose.
         */
        if (
          purposeIsOfRegimeAndHasDefaultValue
          // 1.
          || (honorOtherRegimesOptOut && userPurposeChoice === false
            // 2.
            || honorOtherRegimesOptIn && userPurposeChoice === true)
          // 3.
        ) {
          return userPurposeChoice;
        }
      }
      if (!userHasMadeCurrentPurposeChoice && purposeIsOfRegimeAndHasDefaultValue) {
        /**
         * User has not made a choice for the current purpose
         * and there is a regime default value for the current purpose.
         *
         * Conditions to respect current purpose regime default value:
         *   1. User has not made any choice for any of the purposes the
         *      feature falls under.
         *   2. User has made at least one choice for any of the purposes the
         *      feature falls under but since honorUserChoicesOverRegimeDefaults is false
         *      take into account userFeaturePurposeChoices AND regime default values.
         *   3. When only honoring current regime user purposes choice over regime defaults
         *      and user hasn't made a current regime purpose choice and ignoring
         *      other regime user purpose choices.
         *      Use case:
         *      honorUserChoicesOverRegimeDefaults: true
         *      honorOtherRegimesOptOut: false,
         *      honorOtherRegimesOptIn: false,
         *      is equivalent to
         *      honorCurrentRegimeUserPurposeOverRegimeDefaults and ignore
         *      other regimes purposes choice, if any.
         */
        if (
          !hasUserMadeAnyChoice
          // 1.
          || hasUserMadeAnyChoice && !honorUserChoicesOverRegimeDefaults
          // 2.
          || honorUserChoicesOverRegimeDefaults
          && !hasUserMadeAnyChoiceFromCurrentRegime
          && hasUserMadeAnyChoiceFromAnotherRegime
          && (!honorOtherRegimesOptOut && !honorOtherRegimesOptIn)
          // 3.
        ) {
          return currentPurposeDefaultValue;
        }
      }

      // All the above conditions were not met, it's safe to mark true
      // for current purpose.
      return true;
    });

  } catch (e) {
    throw e;
  }
}

/**
 * If featureConfig has override defined, returns a boolean value.
 * Otherwise, returns undefined.
 */
const evaluateConfigOverride = ({
  regime,
  blockRegimes,
  enableRegimes,
}) => {
  if (blockRegimes.length && blockRegimes.includes(regime)) return false;
  if (enableRegimes.length && enableRegimes.includes(regime)) return true;


};

/**
 * Always pass npa in Serbia GDPR.
 * TODO: Cleanup once npa flag is always passed for GDPR.
 */
const shouldEnableNPAForSerbiaGDPR = ({
  name,
  regime,
  geoIPCountryCode,
}: {
  name: string,
  regime: Regime,
  geoIPCountryCode: string,
}) => name === 'gptPiiGDPR' && geoIPCountryCode === 'RS' && regime === GDPR;

export const determinesIfUserMadeAnyChoiceBasedOnRegime = ({
  featurePurposes,
  userPurposesChoicesMap,
  regimeDefaultPurposesMap,
  checkChoicesFromCurrentRegime,
  checkChoicesFromAnotherRegime,
}: {
  featurePurposes: Purpose[]
  userPurposesChoicesMap: UserConsents,
  regimeDefaultPurposesMap: RegimeDefaultPurposes,
  checkChoicesFromCurrentRegime: boolean,
  checkChoicesFromAnotherRegime: boolean,
}) => featurePurposes.some((currPurposeKey) => {
  const userPurposeChoice = userPurposesChoicesMap[currPurposeKey];
  const hasUserMadeCurrentPurposeChoice = typeof userPurposeChoice === 'boolean';
  const currentPurposeDefaultValue = regimeDefaultPurposesMap[currPurposeKey];
  const purposeIsOfRegimeAndHasDefaultValue = typeof currentPurposeDefaultValue === 'boolean';

  if (hasUserMadeCurrentPurposeChoice && (
    checkChoicesFromCurrentRegime && purposeIsOfRegimeAndHasDefaultValue
    || checkChoicesFromAnotherRegime && !purposeIsOfRegimeAndHasDefaultValue
  )) {
    return true;
  }

  return false;
});
