export const TABOOLA_HACK_LOCALE_EXCLUSIONS = ['de-DE', 'en-IN', 'en-US', 'es-US'];

export const TABOOLA_HACK_PAGE_KEYS = [
  'alertDetails',
  'article',
  'fiveday',
  'home',
  'hourly',
  'index',
  'monthly',
  'tenday',
  'today',
  'weekend',
];

export const TABOOLA_PAGE_TYPES = {
  home: 'home',
  article: 'article',
  video: 'video',
  today: 'category',
  fiveday: 'category',
  tenday: 'category',
  hourly: 'category',
  weekend: 'category',
  monthly: 'category',
  default: 'other',
};

export const TABOOLA_LOADERS = {
  domestic: '//cdn.taboola.com/libtrc/theweatherchannel/loader.js',
  international: '//cdn.taboola.com/libtrc/theweatherchannelinternational-network/loader.js',
};

export const TABOOLA_BASE_PROPS = {
  ttl: 900,
  type: 'generic',
  limit: 2,
  moduleLazyload: false,
  offsetBottom: 1000,
  wrapInPanel: true,
  titles: {
    'de-CH': {
      title: 'Gesponserte Inhalt',
    },
    'en-AU': {
      title: 'Sponsored Content',
    },
    'es-AR': {
      title: 'Contenido patrocinado',
    },
    'es-CL': {
      title: 'Contenido Patrocinado',
    },
    'es-CO': {
      title: 'Contenido Patrocinado',
    },
    'es-MX': {
      title: 'Contenido patrocinado',
    },
    'es-US': {
      title: 'Contenido patrocinado',
    },
    'id-ID': {
      title: 'Konten sponsor',
    },
    'ja-JP': {
      title: 'スポンサードコンテンツ',
    },
    'nl-NL': {
      title: 'Gesponsorde inhoud',
    },
    'no-NO': {
      title: 'Sponset innhold',
    },
    'pl-PL': {
      title: 'Sponsorowana treść',
    },
    'pt-BR': {
      title: 'Conteúdo patrocinado',
    },
    'ru-RU': {
      title: 'Материалы спонсоров',
    },
    'zh-CN': {
      title: '赞助内容',
    },
    'zh-HK': {
      title: '赞助内容',
    },
  },
  bypassServer: true,
  uuid: 'abcdef01-2345-6789-abcd-ef0123456789',
};
