import { eventChannel, END } from 'redux-saga';
import {
  call,
  put,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { getStateFromKeySelector } from '../selectors';
import { setToStorage } from '../storage';
import { persistPending, persistComplete } from '../actions';
import { getAllowedState } from './getAllowedState';

/**
 * Sets up watchers to persist data at a given key in redux state to a storage store.
 * @param {string}    reducerKey            Key in redux state
 * @param {Object}    [options]
 * @param {boolean}   [options.flush]       Should or should not flush state before unload
 * @param {string}    [options.key]         Key in storage store (defaults to reducerKey)
 * @param {string[]}  [options.allowlist]   Allowed list of sub-state keys
 */
export function* persistSaga(reducerKey, {
  flush = false,
  key = reducerKey,
  allowlist = [],
} = {}) {
  yield spawn(watcher, reducerKey, key, allowlist);

  if (flush) {
    yield spawn(flushToStorage, reducerKey, key, allowlist);
  }
}

export function* watcher(reducerKey, key, allowlist) {
  yield takeLatest(persistActionPatternMatcher, persistWatcher, reducerKey, key, allowlist);
}

/**
 * Watches for persist action types, and persists to storage.
 * @param {string}    reducerKey
 * @param {string}    storageKey
 * @param {string[]}  allowlist
 */
export function* persistWatcher(reducerKey, storageKey, allowlist) {
  yield call(persistToStorage, reducerKey, storageKey, allowlist);
}

/**
 * Only matches actions types ending in '/PERSIST'.
 * Matched action types trigger setting data to storage.
 * @param  {Object} action
 * @param  {string} action.type
 * @return {boolean}
 */
function persistActionPatternMatcher({ type }) {
  const actionTypeRegex = /\/PERSIST$/;

  return actionTypeRegex.test(type);
}

/**
 * Listens for the `beforeunload` event to persist to storage one last time before
 * the user leaves the page.
 * @param {string}    reducerKey
 * @param {string}    storageKey
 * @param {string[]}  allowlist
 */
export function* flushToStorage(reducerKey, storageKey, allowlist) {
  const unloadChannel = yield call(createBeforeunloadChannel);

  yield take(unloadChannel);
  yield call(persistToStorage, reducerKey, storageKey, allowlist);
}

/**
 * Sets up a channel to signal a flush of state to storage before leaving the page.
 */
export function createBeforeunloadChannel() {
  if (!__CLIENT__) return;

  return eventChannel((emit) => {
    window.addEventListener('beforeunload', () => {
      emit(true);
    });

    // Must return unsubscribe function.
    return () => emit(END);
  });
}

/**
 * Persists state from redux at given key to storage.
 * @param {string}    reducerKey
 * @param {string}    key
 * @param {string[]}  allowlist
 * @param {Function}  stateFromKeySelector
 */
export function* persistToStorage(
  reducerKey,
  key,
  allowlist,
  stateFromKeySelector = getStateFromKeySelector(reducerKey),
) {
  yield put(persistPending());

  let state = yield select(stateFromKeySelector);

  state = yield call(getAllowedState, state, allowlist);

  yield call(setToStorage, key, state);
  yield put(persistComplete());
}
