import { env } from '@wxu/env';
import { createLogger } from '@wxu/logger';
import { doTaboolaHack } from './Taboola-Hack/doTaboolaHack';
import { applyTaboolaOverrides } from './applyTaboolaOverrides';

const logger = createLogger('transform-page-config');

/**
 * Transforms a moonracer page config into a more accessible object.
 *
 * @param   {Object<string, *>}   moonracerConfig
 * @param   {Object}              [args]
 * @param   {boolean}             [args.isFrontEndApi]
 * @param   {string}              [args.locale]
 * @param   {string}              args.pageKey
 * @param   {string}              args.deviceClass
 * @returns {Object<string, *>}
 */
export function transformPageConfig(
  moonracerConfig,
  {
    isFrontEndApi = false,
    locale = 'en-US',
    pageKey,
    deviceClass,
    taboolaOverrides,
  } = {},
) {
  const configExists = pageConfigExists(moonracerConfig);

  if (!configExists) return null;

  const meta = getMetaData(moonracerConfig);
  const layout = getLayoutData(moonracerConfig);
  const regions = getRegionsData(moonracerConfig);

  // TODO: Remove this hack/fix once WxuHtmlHead has been
  // added into to all HTML page configs in Moonracer, and
  // the AmpHtmlHead is added into all Amp page configs!
  migrateHtmlHead(regions, layout);

  const pageConfig = {
    meta,
    layout,
    regions,
  };

  // For /api/v1/ UI services don't add.
  if (!isFrontEndApi && meta.page_code !== 'rss') {

    // TODO: Remove the Taboola International hack as
    // soon as MEW allows us easier/more-correct way
    doTaboolaHack(pageConfig, pageKey, locale);

    if (taboolaOverrides) {
      try {
        applyTaboolaOverrides(pageConfig, taboolaOverrides);
      } catch (e) {
        //
      }
    }

    if (canUsePrivacyFeedCard(deviceClass, pageKey)) {
      regions.main.push({
        component: 'WxuPrivacyFeedCard',
        props: {
          id: 'WxuPrivacyFeedCard',
          ttl: 0,
          uuid: '3b774ee7-1be4-4d8e-b37c-6e7fdecd83c1',
        },
      });
    }

    regions.main.push({
      component: 'WxuPrivacyDataNotice',
      props: {
        id: 'WxuPrivacyDataNotice',
        ttl: 0,
        uuid: '7ba809f2-44e7-4620-8c90-12aab7b79f2a',
      },
    });

    regions.main.push({
      component: 'WxuPrivacyConsentModal',
      props: {
        id: 'WxuPrivacyConsentModal',
        ttl: 0,
        uuid: '0f1f4b82-789b-4e60-8403-fa4f1c49cb83',
      },
    });

  }

  if (__DEV__) {
    try {
      // eslint-disable-next-line global-require
      const pageModifier = require('../mocks/pageModifier');

      return pageModifier(pageConfig);
    } catch (e) {
      return pageConfig;
    }
  }

  return pageConfig;
}

/**
 * Gets the page config data stored under the `data` field,
 * whether it be an array or an object.
 *
 * @param {Object<string, *>} moonracerConfig
 * @returns {Object<string, *>}
 */
export function getConfigData(moonracerConfig) {
  const data = moonracerConfig?.data;

  if (Array.isArray(data) && data.length) {
    return data[0];
  }

  return data;
}

/**
 * Checks if page config really exists.
 *
 * @param  {Object<string, *>} moonracerConfig
 * @return {boolean}
 */
export function pageConfigExists(moonracerConfig) {
  const data = getConfigData(moonracerConfig);

  if (!data) return false;

  const hasAttributes = !!data?.attributes;
  const hasIncluded = Array.isArray(moonracerConfig?.included);

  return hasAttributes && hasIncluded;
}

/**
 * Returns general metadata from the page config.
 *
 * @param {Object<string, *>} moonracerConfig
 * @returns {Object<string, *>}
 */
export function getMetaData(moonracerConfig) {
  const data = getConfigData(moonracerConfig);
  const attributes = data?.attributes ?? {};
  const id = data?.id ?? '';
  const url = data?.links?.self?.href ?? '';

  return {
    ...attributes,
    id,
    url,
  };
}

/**
 *
 * @param {Object<string, *>} moonracerConfig
 * @returns {Object<string, *>}
 */
export function getLayoutData(moonracerConfig) {
  const included = moonracerConfig?.included ?? [];
  const layoutConfig = included.find(config => config.type === 'layout');
  const attributes = layoutConfig?.attributes ?? {};
  const layoutOptions = attributes?.configuration_options;

  const data = {
    ...layoutOptions,
    // Maintain backwards compatibility with Layouts which don't have options defined,
    // and use layout name as fallback
    name: layoutOptions?.layoutModule ?? attributes?.name,
  };

  return data;
}

/**
 * Gathers all module_instances and maps them to their region.
 *
 * @param {Object<string, *>} moonracerConfig
 * @returns {Object<string, *[]>}
 */
export function getRegionsData(moonracerConfig) {
  const regions = getLayoutRegions(moonracerConfig);

  mapModulesToRegions(moonracerConfig, regions);

  return regions;
}

/**
 * Returns a mapping of region names to an empty list.
 *
 * @param {Object<string, *>} moonracerConfig
 * @returns {Object<string, *>}
 */
export function getLayoutRegions(moonracerConfig) {
  let regions = [];
  const validRegions = {};
  const included = moonracerConfig?.included ?? null;

  if (included) {
    const layoutData = included.find(data => data.type === 'layout') || {};
    const layoutAreas = layoutData?.attributes?.layout_areas ?? [];

    regions = layoutAreas.map(layoutArea => layoutArea.id);
  }

  for (const region of regions) {
    if (!validRegions[region]) {
      validRegions[region] = [];
    }
  }

  return validRegions;
}

const IGNORE_REGION = 'ignore';

/**
 * For all modules, their data is collected from both "module_instance" data
 * as well as from attributes in the "includes" field of the `moonracerConfig`.
 * These collections are mapped to their respective regions.
 *
 * @param {Object<string, *>} moonracerConfig
 * @param {Object<string, *>} regions
 */
export function mapModulesToRegions(moonracerConfig, regions) {
  const moduleRegions = {};
  const configData = getConfigData(moonracerConfig);
  const moduleInstances = configData?.relationships?.module_instances?.data ?? [];
  const modulesAttributes = moonracerConfig?.included ?? [];
  const modulesById = {};

  // store each module id with its region and weight
  for (const instance of moduleInstances) {
    const { id, meta } = instance;

    modulesById[id] = meta;
  }

  // create map of modules by region
  for (const moduleInstance of modulesAttributes) {
    const {
      id,
      type,
    } = moduleInstance;

    if (type === 'module_instance') {
      const { region = IGNORE_REGION, weight = 0 } = modulesById?.[id] ?? {};
      const props = moduleInstance?.attributes?.configuration ?? {};
      const anchorName = moduleInstance?.attributes?.anchorName;
      const name = moduleInstance?.relationships?.module?.data?.id ?? '';

      moduleRegions[region] = (moduleRegions[region] || []).concat({
        name,
        props: {
          ...props,
          uuid: id,
          anchorName,
        },
        weight,
      });

      // in non-prod environments, log a warning about a missing module relationship
      if (region === IGNORE_REGION && (env.NODE_ENV === 'development' || env.WEBCAKES_ENV !== 'PROD')) {
        logger.warn(`module with id ${id} missing in page_composite.relationships.module_instances`);
      }
    }
  }

  // sort each region's modules by their weight
  for (const region in moduleRegions) {
    // Only modify fields that already exist, as validated earlier
    if (regions[region] || region === IGNORE_REGION) {
      let modules = moduleRegions[region];

      modules.sort((a, b) => a.weight - b.weight);

      modules = modules.map(module => ({
        component: module.name,
        props: module.props,
      }));

      regions[region] = modules;
    }
  }
}

/**
 * Adds the WxuHtmlHead module to the page config, if not already configured.
 * @param {Object<string, *[]>} regions
 */
export function migrateHtmlHead(regions, layout) {
  // select the required head module according
  // to whether we're an Amp or an HTML page
  const reqHeadMod = layout === 'AmpArticle' ? 'AmpHtmlHead' : 'WxuHtmlHead';

  // Check to see if the required head module
  // is already in the page config
  for (const modules of Object.values(regions)) {
    for (const mod of modules) {
      const { component } = mod;

      if (component === reqHeadMod) return;
    }
  }

  // At this point we know the required head module is
  // not in the page config; so, shove it in there
  regions.meta = regions.meta || [];
  regions.meta.unshift({
    component: reqHeadMod,
    props: {},
  });
}

// pageKeys for pages which should not include WxuPrivacyFeedCard
const privacyCardIgnoredPageKeys = [
  'covid19map',
  'betterDecisions',
  'premium',
  'video',
  'dataRights',
  'dsrFormApi',
  'privacySettings',
  'privacySettingsApi',
];
const privacyCardIgnoredDeviceClasses = [
  'desktop',
];

/**
 * @param {string} deviceClass
 * @param {string} pageKey
 * @returns {boolean}
 */
export function canUsePrivacyFeedCard(deviceClass, pageKey) {
  if (!deviceClass || !pageKey) return false;

  return !privacyCardIgnoredDeviceClasses.includes(deviceClass) && !privacyCardIgnoredPageKeys.includes(pageKey);
}
