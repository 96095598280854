import { handleActions } from 'redux-actions';
import { SET_MESSAGE_MODAL, SET_IS_CONTINUOUS_ORDER, IS_CONTINUOUS_ORDER } from './constants';

export const REDUCER_KEY = 'subscriptionContext';

export const reducer = handleActions({
  [SET_MESSAGE_MODAL]: (state, action) => ({
    ...state,
    messageModal: action.payload,
  }),
  [SET_IS_CONTINUOUS_ORDER]: (state, action) => ({
    ...state,
    [IS_CONTINUOUS_ORDER]: action.payload,
  }),

}, {});
